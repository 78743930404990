import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Input, Row, Select, Space, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  AdditionalNotice,
  AdditionalNoticesSortOn,
  AdditionalNoticeStatus,
  CalenderData,
  CompanyCalenderCountResponse,
  CompanyStatsResponse,
  DurationType,
  NoticeAndOrder,
  NoticeAndOrderStatus,
  NoticesAndOrdersSortOn,
  SortOrder,
} from '../../../__generated__/graphql';
import { counselviseClient } from '../../../apollo';
import {
  FETCH_USER_QUERY,
  SUBSCRIPTION_PLANS_QUERY,
} from '../../../app/components/Queries';
import {
  ActiveBox,
  Calendar,
  CloseNotification,
  Files,
  HourGlassLaw,
  Pen,
  Pencil,
  RegularCalendar,
  Users,
  Warning,
} from '../../../assets/svg';
import axiosInstance from '../../../common/axiosInstance';
import {
  CALENDAR_PAYLOAD_TYPE,
  color,
  COMMON_QUERY_PARAMS,
  CURRENT_PAGE,
  currentDate,
  defaultDateFormat,
  EMPTY_DUE_DATES_TEXT,
  FEATURE_NOTICE_BOARD_INDIRECT_TAX,
  GUTTER_VARIATIONS,
  INDIRECT_TAX,
  LIMIT,
  MAX_LIST_NOTICE,
  MESSAGE,
  NOTICE_ORDER_STATUS_OPTIONS,
  PLAN_LIMIT,
  ROUTES,
  upComingDays,
  ZERO_PLACEHOLDER_TEXT,
} from '../../../common/constants';
import { LABEL_TEXT } from '../../../common/labelTexts';
import { formValidatorRules } from '../../../common/utils';
import TableComponent from '../../../components/CommonTable';
import Overview from '../../../components/dashboard/Overview';
import PlansCards from '../../../components/dashboard/PlansCards';
import SyncCalendar from '../../../components/dashboard/SyncCalendar';
import LoaderComponent from '../../../components/LoaderComponent';
import CommonModal from '../../../components/modals/CommonModal';
import useRouter from '../../../hooks/useRouter';
import {
  EProceedingCalendarData,
  StatisticData,
  SubscriptionPlan,
  UpdateStatus,
} from '../../../types/common.type';
import { COMPANY_STATES } from '../companies/graphql/Queries';
import {
  ADDITIONAL_NOTICES_ORDERS_LIST,
  NOTICES_ORDERS_LIST,
} from '../notice/graphql/Queries';
import {
  DISCONNECT_CALENDER,
  UPDATE_ADDITIONAL_NOTICES_STATUS,
  UPDATE_NOTICE_ORDER_STATUS,
} from './graphql/Mutation';
import { CALENDAR_COUNT, CURRENT_SYNC_CALENDAR } from './graphql/Queries';
const { Text } = Typography;
const { required } = formValidatorRules;

const initialFilter = {
  limit: MAX_LIST_NOTICE,
  duration: DurationType?.Custom,
  period: {
    start: currentDate,
    end: upComingDays,
  },
};

const generateStatisticData = (
  companyStats: CompanyStatsResponse,
  iconColor?: string,
): StatisticData[] => {
  return [
    {
      icon: <Pen color={iconColor} />,
      label: LABEL_TEXT.ALL_NOTICES,
      value: companyStats?.allNotices || ZERO_PLACEHOLDER_TEXT,
      key: DurationType?.All,
    },
    {
      icon: <Files color={iconColor} />,
      label: LABEL_TEXT.OPEN_NOTICES,
      value: companyStats?.openNotices || ZERO_PLACEHOLDER_TEXT,
      route: `/${ROUTES.INDIRECT_TAX}/${ROUTES.NOTICE_ORDERS}?${COMMON_QUERY_PARAMS.STATUS}=${NoticeAndOrderStatus.Open}`,
    },
    {
      icon: <Calendar color={iconColor} />,
      label: LABEL_TEXT.DUE_TODAY,
      value: companyStats?.dueToday || ZERO_PLACEHOLDER_TEXT,
      key: DurationType?.DueToday,
    },
    {
      icon: <ActiveBox color={iconColor} />,
      label: LABEL_TEXT.SEVEN_DAYS_DUE,
      value: companyStats?.oneWeekDue || ZERO_PLACEHOLDER_TEXT,
      key: DurationType?.DueIn_7Days,
    },
    {
      icon: <HourGlassLaw color={iconColor} />,
      label: LABEL_TEXT.LAST_24_HOURS,
      value: companyStats?.last24Hours || ZERO_PLACEHOLDER_TEXT,
      key: DurationType?.Last_24Hours,
    },
    {
      icon: <RegularCalendar color={iconColor} />,
      label: LABEL_TEXT.OVERDUE,
      value: companyStats?.overDue || ZERO_PLACEHOLDER_TEXT,
      key: DurationType?.OverDues,
    },
    {
      icon: <Users color={iconColor} />,
      label: LABEL_TEXT.TOTAL_COMPANY,
      value: companyStats?.totalCompany || ZERO_PLACEHOLDER_TEXT,
      route: `/${ROUTES.INDIRECT_TAX}/${ROUTES.COMPANY}`,
    },
    {
      icon: <Warning color={iconColor} />,
      label: LABEL_TEXT.FAILED_LOGIN,
      value: companyStats?.loginFailed || ZERO_PLACEHOLDER_TEXT,
      key: 'failedLogin',
    },
  ];
};

const Dashboard = () => {
  const [statusForm] = Form.useForm();
  const { navigate, location } = useRouter();
  const startOfMonth = dayjs().startOf('month').startOf('day');
  const endOfMonth = dayjs().endOf('month').endOf('day');
  const [isGST, setIsGST] = useState<boolean>(false);
  const [isAdditionalNotices, setIsAdditionalNotices] =
    useState<boolean>(false);
  const [plansModalOpen, setPlansModalOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDateResponses, setSelectedDataResponses] =
    useState<EProceedingCalendarData>();
  const [selectedDateRange, setSelectedDateRange] = useState({
    end: endOfMonth,
    start: startOfMonth,
  });
  const [searchParams] = useSearchParams();
  const [isNotificationVisible, setNotificationVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isGST && location.pathname.includes(INDIRECT_TAX)) {
      setIsGST(true);
    }
  }, [location.pathname, isGST]);

  const { data: { currentUser } = {} } = useQuery(FETCH_USER_QUERY, {
    client: counselviseClient,
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.currentUser?.activeSubscriptions) {
        setNotificationVisible(true);
      }
    },
  });

  const [getCompanyStats, { data: companyStats, loading: loadStats }] =
    useLazyQuery(COMPANY_STATES, {
      fetchPolicy: 'network-only',
      onError() {},
    });

  const [getNoticesAndOrder, { data: noticesAndOrders, loading: loadNotices }] =
    useLazyQuery(NOTICES_ORDERS_LIST, {
      fetchPolicy: 'network-only',
      onError() {},
      variables: {
        filter: initialFilter,
        sort: {
          sortBy: SortOrder.Asc,
          sortOn: NoticesAndOrdersSortOn?.BusinessName,
        },
      },
    });

  const [
    getAdditionalNotices,
    { data: additionalNotices, loading: loadAdditionalNotices },
  ] = useLazyQuery(ADDITIONAL_NOTICES_ORDERS_LIST, {
    fetchPolicy: 'network-only',
    onError() {},
    variables: {
      filter: initialFilter,
      sort: {
        sortBy: SortOrder.Asc,
        sortOn: AdditionalNoticesSortOn?.BusinessName,
      },
    },
  });

  const [updateNoticeOrderStatus, { loading: updateStatusLoading }] =
    useMutation(UPDATE_NOTICE_ORDER_STATUS, {
      onError() {},
      onCompleted: () => {
        statusForm.resetFields();
        setIsModalOpen(false);
        getNoticesAndOrder();
      },
    });
  const [
    updateAdditionalNoticeStatus,
    { loading: loadAdditionalNoticeStatus },
  ] = useMutation(UPDATE_ADDITIONAL_NOTICES_STATUS, {
    onError() {},
    onCompleted: () => {
      statusForm.resetFields();
      setIsAdditionalNotices(false);
      setIsModalOpen(false);
      getAdditionalNotices();
    },
  });
  const { data: { subscriptionPlans } = {}, loading: plansLoading } = useQuery(
    SUBSCRIPTION_PLANS_QUERY,
    {
      client: counselviseClient,
      variables: { filter: { limit: PLAN_LIMIT } },
      onError() {},
      fetchPolicy: 'network-only',
    },
  );

  const [
    getCompanyCalendarCount,
    { data: companyCalenderCount, loading: loadCalendarCounts },
  ] = useLazyQuery(CALENDAR_COUNT, {
    variables: {
      filter: {
        duration: {
          end: selectedDateRange.end || endOfMonth,
          start: selectedDateRange.start || startOfMonth,
        },
      },
    },
    onError() {},
    onCompleted() {},
  });
  useEffect(() => {
    getCompanyStats();
    getNoticesAndOrder();
    getCompanyCalendarCount();
    getAdditionalNotices();
  }, []);

  const {
    data: { currentUserCalender } = {},
    loading: currentUserCalenderLoading,
    refetch,
  } = useQuery(CURRENT_SYNC_CALENDAR, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [disconnectCalendar] = useMutation(DISCONNECT_CALENDER, {
    onError() {},
    onCompleted: () => {
      refetch();
    },
  });

  const calendarDisconnected = () => {
    disconnectCalendar();
  };

  const getSelectedRange = (dateString: [string, string]) => {
    setSelectedDateRange({
      start: dayjs(dateString[0]).startOf('month').startOf('day'),
      end: dayjs(dateString[1]).endOf('month').endOf('day'),
    });
  };

  const filteredPlans = subscriptionPlans?.data?.filter((plan) =>
    plan?.features?.includes(FEATURE_NOTICE_BOARD_INDIRECT_TAX),
  ) as SubscriptionPlan[];

  const latestSubscription = currentUser?.activeSubscriptions
    ?.filter((subscription) =>
      subscription?.features?.includes(FEATURE_NOTICE_BOARD_INDIRECT_TAX),
    )
    ?.reduce((latest, current) => {
      const latestDate = dayjs(latest?.createdAt || 0);
      const currentDate = dayjs(current?.createdAt || 0);
      return currentDate.isAfter(latestDate) ? current : latest;
    }, null);

  const activePlan =
    currentUser?.activeSubscriptions?.filter((item) =>
      item?.features?.includes(FEATURE_NOTICE_BOARD_INDIRECT_TAX),
    ) && latestSubscription?.subscriptionPlan
      ? latestSubscription
      : null;

  const upcoming3Days = dayjs().add(2, 'day').format(defaultDateFormat);
  const currentDate = dayjs().format(defaultDateFormat);
  const queryParams = {
    [COMMON_QUERY_PARAMS.STATUS]: NoticeAndOrderStatus.Open,
    [COMMON_QUERY_PARAMS.DURATION]: DurationType.Custom,
    [COMMON_QUERY_PARAMS.START]: currentDate,
    [COMMON_QUERY_PARAMS.END]: upcoming3Days,
    [COMMON_QUERY_PARAMS.IS_ISSUED_ON]: 'true',
  };

  const updateStatusForm = (values: UpdateStatus) => {
    const { statusForm } = values;
    if (isAdditionalNotices) {
      updateAdditionalNoticeStatus({
        variables: {
          data: {
            status: statusForm?.status as AdditionalNoticeStatus,
            remarks: statusForm?.remarks,
          },
          where: {
            ids: statusForm?.ids ?? [],
          },
        },
      });
    } else {
      updateNoticeOrderStatus({
        variables: {
          where: {
            ids: statusForm?.ids ?? [],
          },
          data: {
            status: statusForm?.status as AdditionalNoticeStatus,
            remarks: statusForm?.remarks,
          },
        },
      });
    }
  };

  const getSelectedNotice = (notice: EProceedingCalendarData) => {
    setSelectedDataResponses(notice);
  };

  const syncCalendarWith = async () => {
    if (searchParams.get('scope')) {
      await axiosInstance.post(
        `/v1/direct-tax-calender/google-calendar-redirect?code=${searchParams.get('code')}`,
        {
          type:
            location.pathname === `/${ROUTES.DIRECT_TAX}`
              ? CALENDAR_PAYLOAD_TYPE.DIRECT_TAX
              : CALENDAR_PAYLOAD_TYPE.INDIRECT_TAX,
        },
      );
    } else {
      await axiosInstance.post(
        `/v1/direct-tax-calender/outlook-redirect?code=${searchParams.get('code')}`,
        {
          type:
            location.pathname === `/${ROUTES.DIRECT_TAX}`
              ? CALENDAR_PAYLOAD_TYPE.DIRECT_TAX
              : CALENDAR_PAYLOAD_TYPE.INDIRECT_TAX,
        },
      );
    }
    refetch();
    navigate(`/${ROUTES.INDIRECT_TAX}`, { replace: true });
  };

  const code = searchParams.get('code');
  useEffect(() => {
    if (code) syncCalendarWith();
  }, [code]);

  const cancelModal = () => {
    setIsModalOpen(false);
    setIsAdditionalNotices(false);
    statusForm.resetFields();
  };

  const columns: ColumnType<NoticeAndOrder>[] = [
    {
      title: LABEL_TEXT.INDEX,
      dataIndex: 'key',
      key: 'key',
      fixed: 'left',
      render: (_val, _, i) => i + 1 + LIMIT * (CURRENT_PAGE - 1),
    },
    {
      title: LABEL_TEXT.GSTIN,
      key: 'gstNumber',
      fixed: 'left',
      render: (item) => (
        <a
          onClick={() =>
            navigate(
              `${ROUTES?.COMPANY}/${ROUTES?.COMPANY_DASHBOARD.replace(':id', item?.company?._id)}`,
            )
          }
        >
          {item?.company?.gstNumber}
        </a>
      ),
    },
    {
      title: LABEL_TEXT.LEGAL_NAME,
      dataIndex: ['company', 'businessName'],
      key: 'businessName',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.TRADE_NAME,
      dataIndex: ['company', 'tradeName'],
      key: 'tradeName',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.USER_NAME,
      dataIndex: ['company', 'username'],
      key: 'username',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.TAXPAYER_TYPE,
      dataIndex: 'type',
      key: 'type',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.DUE_DATES,
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (responseDueDate) => {
        if (!responseDueDate) return '-';

        const formattedDate =
          dayjs?.(responseDueDate).format(defaultDateFormat);
        return <span className="system-red-color">{formattedDate}</span>;
      },
    },
    {
      title: LABEL_TEXT.ACTION,
      fixed: 'right',
      render: (record) => (
        <Space>
          <span
            key="edit"
            onClick={() => {
              setIsModalOpen(true);
              statusForm.setFieldsValue({
                statusForm: {
                  status: record.status,
                  ids: [record._id],
                  remarks: record?.remarks,
                },
              });
            }}
            className="pointer"
          >
            <Pencil color={color} />
          </span>
        </Space>
      ),
    },
  ];

  const AdditionalColumns: ColumnType<AdditionalNotice>[] = [
    {
      title: LABEL_TEXT.INDEX,
      dataIndex: 'key',
      key: 'key',
      fixed: 'left',
      render: (_val, _, i) => i + 1 + LIMIT * (CURRENT_PAGE - 1),
    },
    {
      title: LABEL_TEXT.GSTIN,
      key: 'gstNumber',
      fixed: 'left',
      render: (item) => (
        <a
          onClick={() =>
            navigate(
              `${ROUTES?.COMPANY}/${ROUTES?.COMPANY_DASHBOARD.replace(':id', item?.company?._id)}`,
            )
          }
        >
          {item?.company?.gstNumber}
        </a>
      ),
    },
    {
      title: LABEL_TEXT.LEGAL_NAME,
      dataIndex: ['company', 'businessName'],
      key: 'businessName',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.TRADE_NAME,
      dataIndex: ['company', 'tradeName'],
      key: 'tradeName',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.USER_NAME,
      dataIndex: ['company', 'username'],
      key: 'username',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.TAXPAYER_TYPE,
      dataIndex: 'type',
      key: 'type',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.DUE_DATES,
      dataIndex: 'replyDueDate',
      key: 'replyDueDate',
      render: (responseDueDate) => {
        if (!responseDueDate) return '-';

        const formattedDate =
          dayjs?.(responseDueDate).format(defaultDateFormat);
        return <span className="system-red-color">{formattedDate}</span>;
      },
    },
    {
      title: LABEL_TEXT.ACTION,
      fixed: 'right',
      render: (record) => (
        <Space>
          <span
            key="edit"
            onClick={() => {
              setIsAdditionalNotices(true);
              setIsModalOpen(true);
              statusForm.setFieldsValue({
                statusForm: {
                  status: record?.status,
                  ids: [record._id],
                  remarks: record?.remarks,
                },
              });
            }}
            className="pointer"
          >
            <Pencil color={color} />
          </span>
        </Space>
      ),
    },
  ];

  const loading =
    loadNotices || loadStats || loadCalendarCounts || loadAdditionalNotices;

  return (
    <>
      <div className="dashboard-content-holder relative">
        {!activePlan && isNotificationVisible ? (
          <div className="plan-notification">
            <Text>
              Hey! Your “free trial” package has 2 free clients.{' '}
              <span onClick={() => setPlansModalOpen(true)} className="pointer">
                Get more clients
              </span>
            </Text>
            <span onClick={() => setNotificationVisible(false)}>
              <CloseNotification />
            </span>
          </div>
        ) : null}
        <div className="container">
          <div className="dashboard-content-wrapper mt-32 mb-32">
            <LoaderComponent spinning={loading} size="default">
              <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
                <Col md={6} lg={6} className="section-1">
                  <SyncCalendar
                    calendarCounts={
                      companyCalenderCount?.companyCalenderCount as CompanyCalenderCountResponse
                    }
                    getSelectedNotice={getSelectedNotice}
                    selectedDateResponses={
                      selectedDateResponses as EProceedingCalendarData
                    }
                    getSelectedRange={getSelectedRange}
                    googleCalendarRedirectLink="/v1/direct-tax-calender/google-calendar-auth"
                    outlookCalendarRedirectLink="/v1/direct-tax-calender/outlook-login"
                    currentUserCalendar={currentUserCalender as CalenderData}
                    currentUserCalendarLoading={currentUserCalenderLoading}
                    calendarDisconnected={calendarDisconnected}
                    isGst={isGST}
                  />
                </Col>
                <Col md={18} lg={18} className="d-flex flex-vertical gap-32">
                  <Overview
                    statisticData={generateStatisticData(
                      companyStats?.companyStats as CompanyStatsResponse,
                      color,
                    )}
                  />
                  <div className="d-flex flex-vertical gap-16 ">
                    <div>
                      <Text className="head">Nearing due date for notices</Text>
                      <TableComponent<NoticeAndOrder>
                        columns={columns}
                        dataSource={
                          noticesAndOrders?.noticesAndOrders
                            ?.data as NoticeAndOrder[]
                        }
                        className=" mt-8"
                        scroll={{ x: 'max-content' }}
                        locale={EMPTY_DUE_DATES_TEXT}
                      />
                      <Button
                        type="link"
                        className="underline-btn float-right"
                        onClick={() => {
                          navigate(
                            `/${ROUTES?.INDIRECT_TAX}/${ROUTES?.NOTICE_ORDERS}?${new URLSearchParams(queryParams).toString()}`,
                          );
                        }}
                      >
                        {LABEL_TEXT.VIEW_ALL}
                      </Button>
                    </div>
                    <div>
                      <Text className="head">
                        Nearing due date for additional notices
                      </Text>
                      <TableComponent<AdditionalNotice>
                        columns={AdditionalColumns}
                        dataSource={
                          additionalNotices?.additionalNotices
                            ?.data as AdditionalNotice[]
                        }
                        className=" mt-8"
                        scroll={{ x: 'max-content' }}
                        locale={EMPTY_DUE_DATES_TEXT}
                      />
                      <Button
                        type="link"
                        className="underline-btn float-right"
                        onClick={() => {
                          navigate(
                            `/${ROUTES?.INDIRECT_TAX}/${ROUTES?.ADDITIONAL_NOTICE_ORDERS}?${new URLSearchParams(queryParams).toString()}`,
                          );
                        }}
                      >
                        {LABEL_TEXT.VIEW_ALL}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </LoaderComponent>
          </div>
        </div>
      </div>
      <CommonModal
        open={plansModalOpen}
        footer={false}
        closable={true}
        onCancel={() => setPlansModalOpen(false)}
        maskClosable={false}
        centered={true}
        className="plans-container"
        width={1086}
      >
        <PlansCards
          activePlan={activePlan}
          plans={filteredPlans}
          loading={plansLoading}
          userClient={currentUser?.userClient}
          isGst={isGST}
        />
      </CommonModal>
      <CommonModal
        open={isModalOpen}
        title="Update Status"
        footer={false}
        closable={true}
        onCancel={cancelModal}
        maskClosable={false}
      >
        <div className="create-forms-form">
          <Form
            onFinish={updateStatusForm}
            form={statusForm}
            layout="vertical"
            preserve={false}
          >
            <Form.Item
              label={LABEL_TEXT.STATUS}
              name={['statusForm', 'status']}
              rules={[
                { ...required, message: MESSAGE?.required, whitespace: true },
              ]}
            >
              <Select
                options={NOTICE_ORDER_STATUS_OPTIONS}
                placeholder="Change Status"
              />
            </Form.Item>
            <Form.Item
              label={LABEL_TEXT.REMARK}
              name={['statusForm', 'remarks']}
              rules={[{ whitespace: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={LABEL_TEXT.REMARK}
              name={['statusForm', 'ids']}
              hidden
            >
              <Select mode="multiple" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              loading={
                isAdditionalNotices
                  ? loadAdditionalNoticeStatus
                  : updateStatusLoading
              }
            >
              {LABEL_TEXT.UPDATE}
            </Button>
          </Form>
        </div>
      </CommonModal>
    </>
  );
};

export default Dashboard;
