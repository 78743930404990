import { Button, Card, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { RoundArrow, ThreeLine } from '../../assets/svg';
import { GUTTER_VARIATIONS, INDIRECT_TAX } from '../../common/constants';
import { LABEL_TEXT } from '../../common/labelTexts';
import { PlansModalProps } from '../../types/common.type';
import LoaderComponent from '../LoaderComponent';

const { Text, Title } = Typography;

const PlansCards = ({
  activePlan,
  plans,
  loading,
  userClient,
  isGst,
}: PlansModalProps) => {
  const redirectTo = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}/plans`;
  const redirectPlans = location?.pathname?.includes(INDIRECT_TAX)
    ? `${redirectTo}?isGst=${isGst}`
    : `${redirectTo}`;

  const usedClient =
    (isGst ? userClient?.gstUsedCount : userClient?.directTaxUsedCount) ?? 0;
  const totalClient =
    (isGst ? userClient?.gstCount : userClient?.directTaxCount) ?? 0;
  const activeOrder = activePlan?.subscriptionPlan?.order ?? 0;
  const expirationDate = dayjs(activePlan?.expireDate);
  const currentDate = dayjs();
  const isExpired = expirationDate.isBefore(currentDate);

  return (
    <>
      <div className="plans-content d-flex align-center justify-center flex-vertical gap-64">
        <div className="plans-text">
          <div className="main-text d-flex justify-center">
            <span className="three-lines-svg">
              <ThreeLine color={`${isGst ? '#66D9B3' : '#D966D3'}`} />
            </span>
            <h1 className="text-primaryShade1 h1 semibold">
              Upgrade to more clients
            </h1>
            <span className="round-arrow">
              <RoundArrow color={`${isGst ? '#66D9B3' : '#D966D3'}`} />
            </span>
          </div>
          <p className="body-l text-center">
            Automatically grabs and organizes GST notices in one place, keeping
            track of when you need to respond and by what date.
          </p>
        </div>
        <Row
          className="plans-card-content gap-24 justify-center"
          gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}
        >
          {plans?.map((plan, index) => {
            const canDegrade =
              activeOrder > (plan?.order ?? 0) && usedClient > totalClient;
            const isDisabled = activePlan
              ? (activePlan?.subscriptionPlan?.order ?? 1) >=
                  (plan?.order ?? 0) && !isExpired
              : false;
            const maxClientsForPlan = plan?.featureConfig?.assesseeCount ?? 0;

            return (
              <LoaderComponent spinning={loading} key={index}>
                <Card
                  className={`d-flex justify-center flex-vertical ${
                    activeOrder === plan?.order ? 'active-plan' : ''
                  }`}
                >
                  <div className="card gap-24 d-grid text-center justify-center flex-vertical">
                    <Text className="fs-16">{plan?.title}</Text>
                    <span>
                      <Title level={3} className="price m-0">
                        ₹ {plan?.price}
                      </Title>
                      <span
                        className={`plans-duration fs-16 ${isGst ? 'gst' : ''}`}
                      >
                        {capitalize(plan?.type ?? '')}
                      </span>
                    </span>
                    <Text className="fs-20">
                      {`${
                        isGst
                          ? plan?.featureConfig?.companyCount
                          : plan?.featureConfig?.assesseeCount
                      } Clients`}
                    </Text>
                    {canDegrade && (
                      <p>
                        As per the current plan, you have {totalClient} clients
                        added! Remove{' '}
                        {Math.max(0, usedClient - maxClientsForPlan)} clients to
                        downgrade to “{plan?.title}”
                      </p>
                    )}
                    <Button
                      type="primary"
                      onClick={() => window.open(redirectPlans)}
                      disabled={isDisabled}
                    >
                      {(() => {
                        if (
                          activeOrder > 1 &&
                          (plan?.order ?? 0) < activeOrder
                        ) {
                          return `${LABEL_TEXT.DOWNGRADE_TO} ${plan?.title?.split(' ')[0]}`;
                        }
                        if ((plan?.order ?? 0) === activeOrder) {
                          return LABEL_TEXT.RENEW_PLAN;
                        }
                        return `${LABEL_TEXT.UPGRADE_TO} ${plan?.title?.split(' ')[0]}`;
                      })()}
                    </Button>
                    {activeOrder === plan?.order && (
                      <Button
                        type="default"
                        className={`btn-current-plan ${isGst ? 'gst' : ''}`}
                      >
                        {LABEL_TEXT.CURRENT_PLAN}
                      </Button>
                    )}
                  </div>
                </Card>
              </LoaderComponent>
            );
          })}
        </Row>
      </div>
    </>
  );
};

export default PlansCards;
