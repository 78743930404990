import { Button, Card } from 'antd';
import {
  BriefcaseIcon,
  JudgementsIcon,
  PhoneIcon,
  TemplatesIcon,
} from '../../../assets/svg/index';
import { ROUTES } from '../../../common/constants';
import { LABEL_TEXT } from '../../../common/labelTexts';
import { Cookie } from '../../../common/utils';
import { NoticeboardCardProps } from '../../../types/common.type';
import CommonButton from './CommonButton';

const CardsComponent = () => {
  const idToken = Cookie.get(Cookie.keys.TOKEN);
  const redirectToBriefcase = process.env.REACT_APP_ASK_AI_REDIRECT_URL!;
  const redirectTo = process.env.REACT_APP_REDIRECT_PROFILE_URL!;

  const handleRedirection = (card: NoticeboardCardProps) => {
    if (card?.link) {
      window.open(card?.link);
    }
  };

  const cardData = [
    {
      title: 'Consultation',
      description:
        'Get expert advice anytime with our personalized consultation call service!',
      link: `${redirectTo}${ROUTES?.CONSULTATION}`,
      icon: <PhoneIcon />,
    },
    {
      title: 'Judgements',
      description:
        '10 Lakh+ judgements, across Direct Tax, Indirect Tax and Corporate Law',
      link: `${redirectTo}/${ROUTES.DIRECT_TAX}${ROUTES?.JUDGEMENTS}`,
      icon: <JudgementsIcon />,
    },
    {
      title: 'Templates',
      description:
        'Ready to use templates on income tax, GST, corporate law and startups',
      link: `${redirectTo}${ROUTES?.TEMPLATES}`,
      icon: <TemplatesIcon />,
    },
    {
      title: 'Briefcase',
      description:
        'AI legal assistant - summarise judgements, find precedents and draft arguments',
      link: `${redirectToBriefcase}`,
      icon: <BriefcaseIcon />,
    },
  ];

  return (
    <div className="content d-flex justify-between">
      <div className="d-flex flex-vertical gap-32 text-section align-self-center">
        <span className="d-flex flex-vertical gap-24">
          <h1 className="text-white m-0">
            GET READY TO SUPERCHARGE YOUR TAX PRACTICE!
          </h1>
          <p className="fs-20 text-white m-0">
            Join thousands of professionals leveraging Counselvise to streamline
            their legal and tax practice
          </p>
        </span>
        {!idToken && (
          <span>
            <CommonButton text={LABEL_TEXT.SIGNUP} />
          </span>
        )}
      </div>
      <div className="cards-container">
        {cardData?.map((card, index) => (
          <Card key={index} className="card-content">
            <div className="d-flex gap-8 flex-vertical">
              {card?.icon}
              <h3 className="m-0">{card?.title}</h3>
              <p className="text-medium m-0">{card?.description}</p>
            </div>
            <Button
              onClick={() => handleRedirection(card)}
              type="link"
              className="mt-16 know-more-btn"
            >
              {LABEL_TEXT.KNOW_MORE}
            </Button>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default CardsComponent;
