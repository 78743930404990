import { useLazyQuery } from '@apollo/client';
import { Tabs, Tag } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import {
  AdditionalNoticesType,
  Company,
  DurationType,
  NoticeAndOrderStatus,
} from '../../../__generated__/graphql';
import {
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  NOTICE_TABS,
  ROUTES,
} from '../../../common/constants';
import useQueryValue from '../../../common/hooks/useQueryValue';
import { LABEL_TEXT } from '../../../common/labelTexts';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import useRouter from '../../../hooks/useRouter';
import { COMPANY } from '../companies/graphql/Queries';
import AdditionalNotices from './components/AdditionalNotices';
import NoticeOrder from './components/NoticeOrder';
import { NOTICE_COUNT_QUERY } from './graphql/Queries';

export type COUNT_TYPE = {
  noticeCount: number;
  additionalNoticeCount: number;
};
export type PROPS_TYPE = {
  count: COUNT_TYPE;
  setCount: (arg: COUNT_TYPE) => void;
};

const Notice = () => {
  const { navigate, location, params } = useRouter();
  const urlDataObj = useQueryValue();

  const { id } = params;
  const [companiesData, setCompaniesData] = useState<Company | null>(null);
  const [count, setCount] = useState<COUNT_TYPE>({
    noticeCount: 0,
    additionalNoticeCount: 0,
  });

  const isAdditionalNotice = location?.pathname?.includes(
    ROUTES?.ADDITIONAL_NOTICE_ORDERS,
  );

  const [getCount] = useLazyQuery(NOTICE_COUNT_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getCount({
      variables: {
        filter: {
          companyId: urlDataObj?.[COMMON_QUERY_PARAMS.COMPANY_ID] || id || null,
          duration:
            (urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] as DurationType) ||
            null,
          ids: [],
          isIssuedOn:
            urlDataObj?.[COMMON_QUERY_PARAMS.IS_ISSUED_ON] &&
            urlDataObj?.[COMMON_QUERY_PARAMS.IS_ISSUED_ON] === 'true'
              ? true
              : false,
          /* kept for future use */
          // type: (urlDataObj?.[COMMON_QUERY_PARAMS.NOTICE_TYPE] ||
          //   null) as NoticeAndOrderType,
          ...(urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] ===
            DurationType.Custom && {
            period: {
              start: urlDataObj?.[COMMON_QUERY_PARAMS.START]
                ? dayjs(
                    urlDataObj?.[COMMON_QUERY_PARAMS.START],
                    defaultDateFormat,
                  ).startOf('day')
                : '',
              end: urlDataObj?.[COMMON_QUERY_PARAMS.END]
                ? dayjs(
                    urlDataObj?.[COMMON_QUERY_PARAMS.END],
                    defaultDateFormat,
                  ).endOf('day')
                : '',
            },
          }),
          status:
            (urlDataObj?.[
              COMMON_QUERY_PARAMS.STATUS
            ] as NoticeAndOrderStatus) || null,
          search: urlDataObj?.[COMMON_QUERY_PARAMS.SEARCH] || '',
        },
      },
      onCompleted: (res) => {
        setCount({
          noticeCount: res?.noticeCounts?.noticeOrderCounts ?? 0,
          additionalNoticeCount: res?.noticeCounts?.additionalNoticeCounts ?? 0,
        });
      },
      onError() {},
    });
  }, []);

  const [getCompanies] = useLazyQuery(COMPANY, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted: (response) => {
      if (response?.company) {
        setCompaniesData(response?.company as Company);
      }
    },
  });

  useEffect(() => {
    if (id) {
      getCompanies({
        variables: {
          where: { id: id },
        },
      });
    }
  }, [id]);

  const handleChange = (val: string) => {
    let redirectTo =
      val === NOTICE_TABS?.noticeOrder
        ? ROUTES?.NOTICE_ORDERS
        : ROUTES?.ADDITIONAL_NOTICE_ORDERS;
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const typeParams = searchParams.get(COMMON_QUERY_PARAMS.NOTICE_TYPE);
      const sectionParams = searchParams.get(COMMON_QUERY_PARAMS.SECTION);
      const page = searchParams.get(COMMON_QUERY_PARAMS.PAGE);
      const limit = searchParams.get(COMMON_QUERY_PARAMS.LIMIT);
      if (typeParams || sectionParams || page || limit) {
        if (typeParams === AdditionalNoticesType.Intimation) {
          searchParams.delete(COMMON_QUERY_PARAMS.NOTICE_TYPE);
        }
        searchParams.delete(COMMON_QUERY_PARAMS.SECTION);
        searchParams.delete(COMMON_QUERY_PARAMS.PAGE);
        searchParams.delete(COMMON_QUERY_PARAMS.LIMIT);
      }
      redirectTo = `${redirectTo}?${searchParams.toString()}`;
    }
    navigate(
      id
        ? `/${ROUTES?.INDIRECT_TAX}/${ROUTES?.COMPANY}/${ROUTES?.COMPANY_DASHBOARD.replace(':id', id)}/${redirectTo}`
        : `/${ROUTES?.INDIRECT_TAX}/${redirectTo}`,
      {
        replace: true,
      },
    );
  };

  return (
    <div className="container">
      <div className="mt-16 mb-16">
        <MyBreadcrumb
          gstNumber={companiesData?.gstNumber ?? ''}
          id={(id || companiesData?._id) ?? ''}
        />
      </div>
      <div className="notices-tabs d-flex justify-between align-center">
        <Tabs
          activeKey={
            !isAdditionalNotice
              ? NOTICE_TABS?.noticeOrder
              : NOTICE_TABS?.additionalNotices
          }
          onChange={(val) => handleChange(val)}
          defaultActiveKey={NOTICE_TABS?.noticeOrder}
          className="full-width gst-tabs"
          destroyInactiveTabPane
          items={[
            {
              label: (
                <span className="d-flex gap-8">
                  {LABEL_TEXT.NOTICE_ORDERS}
                  {count.noticeCount > 0 && (
                    <Tag className="counts-tag">{count.noticeCount}</Tag>
                  )}
                </span>
              ),
              key: NOTICE_TABS?.noticeOrder,
              children: <NoticeOrder count={count} setCount={setCount} />,
            },
            {
              label: (
                <span className="d-flex gap-8">
                  {LABEL_TEXT.ADDITIONAL_NOTICE_ORDERS}
                  {count.additionalNoticeCount > 0 && (
                    <Tag className="counts-tag">
                      {count.additionalNoticeCount}
                    </Tag>
                  )}
                </span>
              ),
              key: NOTICE_TABS?.additionalNotices,
              children: <AdditionalNotices count={count} setCount={setCount} />,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Notice;
