export const LABEL_TEXT = {
  /* HEADINGS */
  ASSESSEE_DETAILS: 'Assessee Details',
  DELETE_ASSESSEE: 'Delete Assessee',
  ADD_ASSESSEE: 'Add Assessee',
  UPDATE_ASSESSEE: 'Update Assessee',
  SYNC_LOGS: 'Sync Log',
  PAN_PROFILE: 'Pan Profile',
  JURISDICTION_DETAILS: 'Jurisdiction Details',
  E_PROCEEDING: 'E-Proceeding',
  INCOME_TAX_RETURN_FORM: 'Income Tax Return Forms',
  OTHER_FORM: 'Other Forms',
  OTHER_FILED_FORM: 'Other Filed Forms',
  ITR_FILED_RETURN: 'ITR Filed Return',
  ITR_FILED_RETURN_STATUS: 'ITR Filed Return Status',
  INCOME_TAX_NOTICE: 'Income Tax Notice',
  VIEW_RESPONSES: 'View Responses',

  COMPANY_LIST: 'Company List',
  NOTICE_ORDERS: 'Notices & Orders',
  ADDITIONAL_NOTICE_ORDERS: 'Additional Notices & Orders',
  ADDITIONAL_NOTICE_FOLDER: 'Additional Notice Folder',
  TRACK_RETURN_STATUS: 'Track Return Status',
  COMPANY_PROFILE: 'Company Profile',
  OTHER_DETAILS: 'Other Details',
  BUSINESS_OWNERS: 'Business Owners',
  BUSINESS_ACTIVITIES: 'Business Activities',
  HSN_SAC: 'HSN / SAC',
  RETURN_PERIODICITY: 'Return Periodicity',
  NO_DATA_AVAILABLE: 'No Data Available',
  GSTR1: 'GSTR 1',
  GSTR3B: 'GSTR3B',
  GSTR9: 'GSTR9',
  GSTR9C: 'GSTR9C',
  INTIMATIONS: 'Intimations',
  NOTICES: 'Notices',
  REPLIES: 'Replies',
  ORDERS: 'Orders',
  APPLICATIONS: 'Applications',
  ACK_INTIMATIONS: 'ack./intimations',

  /* BUTTON LABEL TEXT */
  GET_STARTED: 'Get Started',
  LOGIN: 'Log In',
  SIGNUP: 'Sign Up',
  GO_TO_INCOME_TAX: 'Go to Income Tax',
  GO_TO_GST: 'Go to Gst',
  START_FREE_TRIAL: 'Start Free Trial',
  KNOW_MORE: 'Know More',
  VIEW_ALL: 'View All',
  UPDATE: 'Update',
  SUBMIT: 'Submit',
  GOOGLE: 'Google',
  GOOGLE_CALENDAR: 'Google Calendar',
  OUTLOOK: 'Outlook',
  OUTLOOK_CALENDAR: 'Outlook Calendar',
  DISCONNECT: 'Disconnect',
  CURRENT_PLAN: 'Current Plan',
  RENEW_PLAN: 'Renew Plan',
  IMPORT_BULK_USERS: 'Import Bulk Users',
  DOWNLOAD_TEMPLATES: 'Download Templates',
  SYNC: 'Sync',
  RETRY_SYNC: 'Retry Sync',
  VIEW_SYNC_LOG: 'View Sync Log',
  CLEAR_ALL: 'Clear All',
  UPLOAD_CSV: 'Upload Csv',
  UPGRADE_PLAN: 'Upgrade Plan',
  EXPORT_TO_CSV: 'Export to Csv',
  BACK_TO_LIST: 'Back to List',
  DOWNLOAD_FORM: 'Download Form',
  DOWNLOAD_RECEIPT: 'Download Receipt',
  DOWNLOAD_ATTACHMENT: 'Download Attachment',
  BULK_UPDATE_STATUS: 'Bulk Update Status',
  ASK_AI: 'Ask AI',
  DOWNGRADE_TO: 'Downgrade to',
  UPGRADE_TO: 'Upgrade to',

  ADD_COMPANY: 'Add Company',
  UPDATE_COMPANY: 'Update Company',
  ADD_BULK_COMPANIES: 'Add Bulk Companies',

  /* COLUMNS TITLE */
  INDEX: '#',
  PAN: 'PAN',
  NAME_OF_ASSESSEE: 'Name of Assessee',
  REFERENCE_ID: 'Reference Id',
  NOTICE_REFERENCE_ID: 'Notice Reference Id',
  ASSESSMENT_YEAR: 'A.Y.',
  FINANCIAL_YEAR: 'F.Y.',
  DUE_DATE: 'Due Date',
  ACTION: 'Action',
  USERNAME: 'Username',
  PHONE_NUMBER: 'Phone Number',
  LAST_DOWNLOADED: 'Last Downloaded',
  STATUS: 'Status',
  ACTIVE_LOG: 'Active Log',
  STATUS_MESSAGE: 'Status Message',
  FULL_ASSESSMENT_YEAR: 'Assessment Year',
  FULL_FINANCIAL_YEAR: 'Financial Year',
  ACKNOWLEDGEMENT_NUMBER: 'Acknowledgement Number',
  ACKNOWLEDGEMENT_NO: 'Acknowledgement No',
  FILING_DATE: 'Filing Date',
  DATE_OF_FILING: 'Date of Filing',
  TYPE: 'Type',
  FORM_NUMBER: 'Form Number',
  NUMBER_OF_FILINGS: 'Number Of Filings',
  SUBMITTED_DATE: 'Submitted Date',
  FORM_SUBMITTED: 'Form Submitted',
  FILING_TYPE: 'Filing Type',
  FILED_BY: 'Filed By',
  PROCEEDINGS_NAME: 'Proceedings Name',
  ISSUED_DATE: 'Issued Date',
  RESPONSE_DUE_DATE: 'Response Due Date',
  PROCEEDING_CLOSURE_DATE: 'Proceeding Closure Date',
  PROCEEDING_CLOSURE_ORDER: 'Proceeding Closure Order',
  PROCEEDING_LIMITATION_DATE: 'Proceeding Limitation Date',
  EMAIL: 'Email',
  NOTICE_US: 'Notice u/s',
  DOCUMENT_REFERENCE_ID: 'Document Reference Id',
  DESCRIPTION: 'Description',
  PROCEEDING_OPEN_DATE: 'Proceeding Open Date',
  TYPE_OF_PROCEEDING: 'Type of Proceeding',
  REMARKS: 'Remarks',
  DATE_OFF_INCORPORATION: 'Date of Incorporation',
  ADDRESS: 'Address',
  AREA_CODE: 'Area Code',
  AO_TYPE: 'AO Type',
  AO_NUMBER: 'AO Number',
  RANGE_CODE: 'Range Code',
  JURISDICTION: 'Jurisdiction',
  EMAIL_ID: 'Email Id',
  BUILDING_NAME: 'Building Name',
  RESPONSE_REMARKS: 'Response/remarks',
  SUBMIT_DATE: 'Submit Date',
  ATTACHMENT: 'Attachment',
  PROCEEDING_NAME: 'Proceeding Name',
  ASSESSEE_NAME: 'Assessee Name',
  DOCUMENT_REF_ID: 'Document Reference Id',
  NOTICE_SECTION: 'Notice Section',
  SERVED_ON: 'Served on',

  GSTIN: 'GSTIN',
  ARN: 'ARN',
  LEGAL_NAME: 'Legal Name',
  TRADE_NAME: 'Trade Name',
  BUSINESS_NAME: 'Business Name',
  USER_NAME: 'User Name',
  TAXPAYER_TYPE: 'Taxpayer Type',
  DUE_DATES: 'Due Dates',
  PERIOD: 'Period',
  GSTR_1_FILING_DATE: 'GSTR-1 Filing Date',
  GSTR_3B_FILING_DATE: 'GSTR-3B Filing Date',
  DATE_OF_ISSUE_OF_ORDER: 'Date of Issue of Order',
  CENTRE_JURISDICTION: 'Centre Jurisdiction',
  STATE_JURISDICTION: 'State Jurisdiction',
  DATE_OF_REGISTRATION: 'Date of Registration',
  CONSTITUTION_OF_BUSINESS: 'Constitution of Business',
  COMPLIANCE_RATINGS: 'Compliance Ratings',
  FIELD_VISIT_CONDUCTED: 'Field Visit Conducted',
  GSTIN_UIN_STATUS: 'GSTIN/UIN Status',
  GSTIN_UIN_TEMPORARY_ID: 'GSTIN/UIN/Temporary Id',
  AMOUNT_OF_DEMAND: 'Amount of Demand',
  ISSUED_BY: 'Issued By',
  ISSUANCE_DATE: 'Issuance Date',
  DATE: 'Date',
  ISSUE_DATE: 'Issue Date',
  REPLY_DATE: 'Reply Date',
  ORDER_DATE: 'Order Date',
  SUBMISSION_DATE: 'Submission Date',
  REPLY_FILED_AGAINST: 'Reply Filed Against',
  SECTION_NO: 'Section No',
  ORDER_NO: 'Order No',
  REFERENCE_NO: 'Reference No',
  SUBJECT: 'Subject',
  NOTICE_ORDER_DESCRIPTION: 'Notice/order Description',
  REPLY_DUE_DATE: 'Reply Due Date',
  NOTICE_TYPE: 'Notice Type',
  SUBMISSION_AMOUNT: 'Submission Amount',
  ATTACHMENTS: 'Attachments',
  OPTION_FOR_PERSONAL_HEARING: 'Option for Personal Hearing',
  PERSONAL_HEARING: 'Personal Hearing',
  ORIGINAL_DUE_DATE: 'Original Due Date',
  FILED_UP: 'Filed Up',
  CASE_ID: 'Case Id',
  APPLICATION_DATE: 'Date of Application/case Creation',
  ACK_INTIMATION_AND_ATTACHMENTS: 'Ack. /Intimation and Attachments',
  TYPE_OF_DOCUMENTS: 'Type of Documents',
  VIEW_DOCUMENTS: 'View Documents',

  /* STATISTIC LABELS */
  ALL_NOTICES: 'All Notices',
  PENDING_NOTICES: 'Pending Notices',
  OPEN_NOTICES: 'Open Notices',
  DUE_TODAY: 'Due Today',
  SEVEN_DAYS_DUE: '7 Days Due',
  LAST_24_HOURS: 'Last 24 Hours',
  OVERDUE: 'Overdue',
  TOTAL_PAN: 'Total PAN',
  TOTAL_COMPANY: 'Total Company',
  FAILED_LOGIN: 'Failed Login',

  ACTIVE_INACTIVE: 'Active/Inactive',
  SELECT_CLIENT: 'Select Client',
  USER_ID: 'User Id',
  PASSWORD: 'Password',
  REMARK: 'Remark',
  DURATION: 'Duration',
  GST_USERNAME: 'GST Username',
  GST_PASSWORD: 'GST Password',
  IDS: 'ids',

  BACK_HOME: 'Back Home',
  RELOAD: 'Reload',
  OKAY: 'Okay',

  CSV_UPLOAD_TEXT: 'Click or Drag Csv File to This Area to Upload',
};
