import { useLazyQuery } from '@apollo/client';
import { Button, Card, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import {
  AdditionalNotice,
  AdditionalNoticesSortOn,
  CompanyStatsResponse,
  DurationType,
  NoticeAndOrder,
  NoticeAndOrderStatus,
  NoticesAndOrdersSortOn,
  SortOrder,
} from '../../../__generated__/graphql';
import {
  ActiveBox,
  Calendar,
  Files,
  HourGlassLaw,
  Pen,
  RegularCalendar,
} from '../../../assets/svg';
import {
  color,
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  EMPTY_STATE,
  MIN_LIMIT,
  ROUTES,
  ZERO_PLACEHOLDER_TEXT,
} from '../../../common/constants';
import { LABEL_TEXT } from '../../../common/labelTexts';
import TableComponent from '../../../components/CommonTable';
import Overview from '../../../components/dashboard/Overview';
import LoaderComponent from '../../../components/LoaderComponent';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import useRouter from '../../../hooks/useRouter';
import { StatisticData } from '../../../types/common.type';
import { ADDITIONAL_NOTICES_ORDERS_LIST } from '../notice/graphql/Queries';
import AdditionalNotices from './component/AdditionalNotice';
import NoticeOrder from './component/Notice';
import CardList from './component/OtherDetails';
import ProfileCard from './component/ProfileCard';
import { FilingDataType } from './graphql/clients.type';
import {
  COMPANY,
  COMPANY_STATES,
  GET_TRACK_RETURN_STATUS,
  NOTICES_AND_ORDERS,
} from './graphql/Queries';
const { Text } = Typography;

const PanDashboard = () => {
  const { navigate, params } = useRouter();
  const { id } = params;
  const [filingData, setFilingData] = useState<FilingDataType[] | null>(null);

  const [getCompany, { data: company, loading }] = useLazyQuery(COMPANY, {
    fetchPolicy: 'network-only',
    onError() {},
    variables: { where: { id: id } },
  });

  const { gstNumber, tradeName } = useMemo(() => {
    return {
      gstNumber: company?.company?.gstNumber,
      tradeName: company?.company?.tradeName,
    };
  }, [company?.company]);

  const [getCompanyStats, { data: companyStats, loading: loadStats }] =
    useLazyQuery(COMPANY_STATES, {
      fetchPolicy: 'network-only',
      onError() {},
      variables: { where: { id: id } },
    });

  const [getNoticesAndOrder, { data: noticesAndOrders, loading: loadNotices }] =
    useLazyQuery(NOTICES_AND_ORDERS, {
      fetchPolicy: 'network-only',
      onError() {},
      variables: {
        filter: { limit: MIN_LIMIT, companyId: id },
        sort: {
          sortBy: SortOrder?.Desc,
          sortOn: NoticesAndOrdersSortOn?.IssuanceDate,
        },
      },
    });

  const [getTrackReturnStatus, { loading: loadTRSList }] = useLazyQuery(
    GET_TRACK_RETURN_STATUS,
    {
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          financialYear: null,
          companyId: id as string,
          skip: 0,
          limit: MIN_LIMIT,
        },
      },
      onError() {},
      onCompleted: (response) => {
        if (response?.trackReturnStatusList) {
          const gstr1List = response?.trackReturnStatusList?.GSTR1 || [];
          const gstr3bList = response?.trackReturnStatusList?.GSTR3B || [];

          const mappedData = gstr1List
            .map((gstr1Item) => {
              const gstr3bItem = gstr3bList.find(
                (gstr3bItem) =>
                  gstr3bItem?.financialYear === gstr1Item?.financialYear &&
                  gstr3bItem?.taxPeriod === gstr1Item?.taxPeriod,
              );

              if (gstr3bItem) {
                return {
                  financialYear: gstr1Item?.financialYear,
                  taxPeriod: gstr1Item?.taxPeriod,
                  gstr1FilingDate: gstr1Item?.dateOfFiling,
                  gstr3bFilingDate: gstr3bItem?.dateOfFiling || '-',
                };
              }
              return null;
            })
            .filter((item) => item !== null);

          const limitedData = mappedData.slice(0, 3);

          setFilingData(limitedData as FilingDataType[]);
        }
      },
    },
  );

  const [
    getAdditionalNotices,
    { data: additionalNotices, loading: loadAdditionalNotices },
  ] = useLazyQuery(ADDITIONAL_NOTICES_ORDERS_LIST, {
    fetchPolicy: 'network-only',
    onError() {},
    variables: {
      filter: { limit: MIN_LIMIT, companyId: id },
      sort: {
        sortBy: SortOrder.Desc,
        sortOn: AdditionalNoticesSortOn?.ReplyDueDate,
      },
    },
  });

  useEffect(() => {
    getNoticesAndOrder();
    getCompanyStats();
    getCompany();
    getTrackReturnStatus();
    getAdditionalNotices();
  }, []);

  const TRSColumns: ColumnType<FilingDataType>[] = [
    {
      title: LABEL_TEXT.FULL_FINANCIAL_YEAR,
      dataIndex: 'financialYear',
      key: 'financialYear',
      render: (text) => text ?? '-',
    },
    {
      title: LABEL_TEXT.PERIOD,
      dataIndex: 'taxPeriod',
      key: 'taxPeriod',
      render: (text) => text ?? '-',
    },
    {
      title: LABEL_TEXT.GSTR_1_FILING_DATE,
      dataIndex: 'gstr1FilingDate',
      key: 'gstr1FilingDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: LABEL_TEXT.GSTR_3B_FILING_DATE,
      dataIndex: 'gstr3bFilingDate',
      key: 'gstr3bFilingDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
  ];

  const generateStatisticData = (
    companyStats: CompanyStatsResponse,
    iconColor?: string,
  ): StatisticData[] => {
    return [
      {
        icon: <Pen color={iconColor} />,
        label: LABEL_TEXT.ALL_NOTICES,
        value: companyStats?.allNotices || ZERO_PLACEHOLDER_TEXT,
        key: DurationType?.All,
      },
      {
        icon: <Files color={iconColor} />,
        label: LABEL_TEXT.OPEN_NOTICES,
        value: companyStats?.openNotices || ZERO_PLACEHOLDER_TEXT,
        route: `${ROUTES.NOTICE_ORDERS}?${COMMON_QUERY_PARAMS.STATUS}=${NoticeAndOrderStatus.Open}`,
      },
      {
        icon: <Calendar color={iconColor} />,
        label: LABEL_TEXT.DUE_TODAY,
        value: companyStats?.dueToday || ZERO_PLACEHOLDER_TEXT,
        key: DurationType?.DueToday,
      },
      {
        icon: <ActiveBox color={iconColor} />,
        label: LABEL_TEXT.SEVEN_DAYS_DUE,
        value: companyStats?.oneWeekDue || ZERO_PLACEHOLDER_TEXT,
        key: DurationType?.DueIn_7Days,
      },
      {
        icon: <HourGlassLaw color={iconColor} />,
        label: LABEL_TEXT.LAST_24_HOURS,
        value: companyStats?.last24Hours || ZERO_PLACEHOLDER_TEXT,
        key: DurationType?.Last_24Hours,
      },
      {
        icon: <RegularCalendar color={iconColor} />,
        label: LABEL_TEXT.OVERDUE,
        value: companyStats?.overDue || ZERO_PLACEHOLDER_TEXT,
        key: DurationType?.OverDues,
      },
    ];
  };

  const load =
    loadNotices || loadTRSList || loadStats || loading || loadAdditionalNotices;

  return (
    <div className="container">
      <LoaderComponent spinning={load}>
        <div className="mt-16 mb-16 d-flex justify-between">
          <MyBreadcrumb username={gstNumber ?? ''} />
          <Text className="title">
            {gstNumber} | {tradeName}
          </Text>
        </div>
        <div className="d-flex flex-vertical gap-24 mb-16">
          <ProfileCard
            title={LABEL_TEXT.COMPANY_PROFILE}
            data={company?.company}
          />
          <Overview
            statisticData={generateStatisticData(
              companyStats?.companyStats as CompanyStatsResponse,
              color,
            )}
          />
          <div className="d-flex gap-24 full-width">
            <Card className="width-percent-50">
              <div className="d-flex flex-vertical gap-16">
                <div className="d-flex align-center justify-between">
                  <Text className="heading">{LABEL_TEXT.NOTICE_ORDERS}</Text>
                  <Button
                    type="link"
                    className="underline-btn"
                    onClick={() => navigate(`${ROUTES?.NOTICE_ORDERS}`)}
                  >
                    {LABEL_TEXT.VIEW_ALL}
                  </Button>
                </div>
                <div className="notices-card">
                  <NoticeOrder
                    details={
                      noticesAndOrders?.noticesAndOrders
                        ?.data as NoticeAndOrder[]
                    }
                  />
                </div>
              </div>
            </Card>
            <Card className="width-percent-50">
              <div className="d-flex flex-vertical gap-16">
                <div className="d-flex align-center justify-between">
                  <Text className="heading">
                    {LABEL_TEXT.ADDITIONAL_NOTICE_FOLDER}
                  </Text>
                  <Button
                    type="link"
                    className="underline-btn"
                    onClick={() =>
                      navigate(`${ROUTES?.ADDITIONAL_NOTICE_ORDERS}`)
                    }
                  >
                    {LABEL_TEXT.VIEW_ALL}
                  </Button>
                </div>
                <div className="notices-card">
                  <AdditionalNotices
                    details={
                      additionalNotices?.additionalNotices
                        ?.data as AdditionalNotice[]
                    }
                  />
                </div>
              </div>
            </Card>
          </div>
          <Card className="full-width">
            <div className="d-flex align-center justify-between mb-16">
              <Text className="heading">{LABEL_TEXT.TRACK_RETURN_STATUS}</Text>
              <Button
                type="link"
                className="underline-btn"
                onClick={() => navigate(`${ROUTES?.TRACK_RETURN_STATUS}`)}
              >
                {LABEL_TEXT.VIEW_ALL}
              </Button>
            </div>
            <div>
              <TableComponent
                columns={TRSColumns}
                dataSource={(filingData as FilingDataType[]) || []}
                loading={loadTRSList}
                locale={EMPTY_STATE}
              />
            </div>
          </Card>
          <div className="d-flex gap-16">
            <CardList cards={company?.company || {}} />
          </div>
        </div>
      </LoaderComponent>
    </div>
  );
};

export default PanDashboard;
