import { Badge, Card, Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  color,
  GUTTER_VARIATIONS,
  NA_PLACEHOLDER_TEXT,
} from '../../../../common/constants';
import { LABEL_TEXT } from '../../../../common/labelTexts';
import { ProfileCardProps } from '../graphql/clients.type';

dayjs.extend(relativeTime);
const { Text } = Typography;

const ProfileCard = ({ title, data }: ProfileCardProps) => {
  return (
    <Card className="pl-8 pr-8">
      <div className="d-flex flex-vertical gap-16">
        <Text className="heading">{title}</Text>
        <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]} className="mt-16">
          <Col xs={8} xl={8} className="d-grid">
            <Text className="title">{LABEL_TEXT.GSTIN}</Text>
            <span className="value">
              {data?.gstNumber || NA_PLACEHOLDER_TEXT}
            </span>
          </Col>
          <Col xs={8} xl={8} className="d-grid">
            <Text className="title">{LABEL_TEXT.TRADE_NAME}</Text>
            <Text className="value">
              {data?.tradeName || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
          <Col xs={8} xl={8} className="d-grid">
            <Text className="title">{LABEL_TEXT.BUSINESS_NAME}</Text>
            <Text className="value">
              {data?.businessName || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
        </Row>
        <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]} className="mt-16">
          <Col xs={8} lg={8} xl={8} className="d-grid">
            <Text className="title">{LABEL_TEXT.CENTRE_JURISDICTION}</Text>
            <Text className="value">
              {data?.centerJurisdiction || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
          <Col xs={8} lg={8} xl={8} className="d-grid">
            <Text className="title">{LABEL_TEXT.STATE_JURISDICTION}</Text>
            <Text className="value">
              {data?.stateJurisdiction || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
          <Col xs={8} lg={8} xl={8} className="d-grid">
            <Text className="title">{LABEL_TEXT.DATE_OF_REGISTRATION}</Text>
            <Text className="value">
              {data?.registrationDate || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
        </Row>
        <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]} className="mt-16">
          <Col xs={8} lg={8} xl={8} className="d-grid">
            <Text className="title">{LABEL_TEXT.CONSTITUTION_OF_BUSINESS}</Text>
            <Text className="value">
              {data?.constitutionOfBusiness || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
          <Col xs={8} lg={8} xl={8} className="d-grid">
            <Text className="title">{LABEL_TEXT.TAXPAYER_TYPE}</Text>
            <Text className="value">
              {data?.taxPayerType || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
          <Col xs={8} lg={8} xl={8} className="d-grid">
            <Text className="title">{LABEL_TEXT.COMPLIANCE_RATINGS}</Text>
            <Text className="value">
              {data?.complianceRating || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
        </Row>
        <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]} className="mt-16">
          <Col xs={8} lg={8} xl={8} className="d-grid">
            <Text className="title">{LABEL_TEXT.FIELD_VISIT_CONDUCTED}</Text>
            <Text className="value">
              {data?.fieldVisitConducted || NA_PLACEHOLDER_TEXT}
            </Text>
          </Col>
          <Col xs={8} lg={8} xl={8} className="d-grid">
            <Text className="title">{LABEL_TEXT.GSTIN_UIN_STATUS}</Text>
            <Text className="value">
              <Badge
                status="default"
                color={color}
                text={data?.gstStatus || NA_PLACEHOLDER_TEXT}
              />
            </Text>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default ProfileCard;
