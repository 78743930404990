import { useLazyQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  Card,
  Carousel,
  ConfigProvider,
  Rate,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { counselviseClient } from '../../apollo';
import { FETCH_USER_QUERY } from '../../app/components/Queries';
import indirectGroupImage from '../../assets/images//indirect-group-image.png';
import desktopCalendar from '../../assets/images/desktop-calendar.png';
import directGroupImage from '../../assets/images/direct-group-image.png';
import directGroupImage2 from '../../assets/images/direct-tax-full-img.png';
import profileImage from '../../assets/images/group-380.png';
import profileImage1 from '../../assets/images/group-384.png';
import indirectGroupImage2 from '../../assets/images/indirect-tax-full-img.png';
import {
  AISuggesionsIcon,
  AutomateRetrievalIcon,
  CalendarIntegrationIcon,
  CentralizeNoticesIcon,
  CloudIcon,
  FilingDetailsIcon,
  Line,
  LockIcon,
  QuotationIcon,
  SmartCategoriesIcon,
  TrackDeadlinesIcon,
  TrackNoticesIcon,
  TrackSubmissionsIcon,
  UsersIcon,
} from '../../assets/svg';
import {
  FEATURE_NOTICE_BOARD_DIRECT_TAX,
  FEATURE_NOTICE_BOARD_INDIRECT_TAX,
  ROUTES,
} from '../../common/constants';
import { LABEL_TEXT } from '../../common/labelTexts';
import { Cookie } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { directTaxThemeConfig } from '../../styles/directTaxThemeConfig';
import { indirectTaxThemeConfig } from '../../styles/indirectTaxThemeConfig';
import { UserData } from '../../types/common.type';
import CommonButton from './components/CommonButton';
import CommonCards from './components/CommonCards';
import CardsComponent from './components/FooterSectionCard';
import PublicCommonFooter from './components/PublicCommonFooter';
import PublicCommonHeader from './components/PublicCommonHeader';
const { Title, Text } = Typography;

function Home() {
  const { navigate } = useRouter();
  const idToken = Cookie.get(Cookie.keys.TOKEN);
  const [userData, setUserData] = useState<UserData | null>(null);
  const redirectUrl = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}${ROUTES?.LOGIN}/?redirect=${process.env.REACT_APP_BASE_URL}`;

  const [getUserDate] = useLazyQuery(FETCH_USER_QUERY, {
    client: counselviseClient,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (idToken) {
      getUserDate({
        onCompleted: (res) => {
          const userData = res?.currentUser as UserData;
          setUserData(userData);
        },
      });
    }
  }, []);

  const handleNavigate = (redirectTo: string) => {
    if (!idToken) {
      window.open(redirectUrl, '_self');
    } else {
      navigate(redirectTo);
    }
  };

  const activePlanDirectTax = userData?.activeSubscriptions?.find((item) =>
    item?.features?.includes(FEATURE_NOTICE_BOARD_DIRECT_TAX),
  );
  const activePlanIndirectTax = userData?.activeSubscriptions?.find((item) =>
    item?.features?.includes(FEATURE_NOTICE_BOARD_INDIRECT_TAX),
  );

  const directTaxCardData = [
    {
      title: 'Automate Retrieval',
      description: 'Fetch notices directly from govt. portals',
      icon: <AutomateRetrievalIcon />,
    },
    {
      title: 'Filing Details',
      description:
        'Monitor Intimation Processing with AY wise ITR filing details',
      icon: <FilingDetailsIcon />,
    },
    {
      title: 'Track Deadlines',
      description: 'Stay on top of due dates with timely reminders',
      icon: <TrackDeadlinesIcon />,
    },
    {
      title: 'AI Powered Suggestions',
      description: 'Get actionable insights for faster responses',
      icon: <AISuggesionsIcon />,
    },
  ];
  const indirectTaxCardData = [
    {
      title: 'Centralize Notices',
      description: 'Organize all your notices on the unified dashboard',
      icon: <CentralizeNoticesIcon />,
    },
    {
      title: '⁠Calendar Integration',
      description: 'Integration with Google and Outlook Calender',
      icon: <CalendarIntegrationIcon />,
    },
    {
      title: 'Smart Categories',
      description: 'Instant view of your Notices by Category',
      icon: <SmartCategoriesIcon />,
    },
    {
      title: 'Track Submissions',
      description: 'Track your submissions from the dashboard',
      icon: <TrackSubmissionsIcon />,
    },
  ];
  const testimonials = [
    {
      name: 'Nilisha Bhansali',
      title: 'CA, Tax Lawyer',
      review:
        'Thanks to NoticeBoard, I’ve stopped stressing about hearings. The reminders and calendar integration are game-changers!!',
      rating: 5,
      avatar: profileImage,
    },
    {
      name: 'Abhay Dhanik',
      title: 'GST Consultant',
      review:
        'Managing client notices was a mess before NoticeBoard. Now, everything is organized, and I can focus on what matters most.',
      rating: 5,
      avatar: profileImage1,
    },
    {
      name: 'Rishika Sarda',
      title: 'CA',
      review:
        'NoticeBoard has made managing IT and GST notices so easy. I no longer worry about missed deadlines, and the reminders are a huge help!',
      rating: 5,
      avatar: profileImage,
    },
    {
      name: 'Dalzin Madan',
      title: 'CA',
      review:
        'This tool has simplified my work. All client notices in one place with deadline tracking—exactly what every CA needs!',
      rating: 5,
      avatar: profileImage1,
    },
  ];
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    draggable: true,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <PublicCommonHeader />
      <div className="home-container d-flex flex-vertical gap-40">
        <section className="container noticeboard-slider">
          <div className="hero-section">
            <div className="pt-64 pb-64 d-flex gap-24 align-center flex-vertical">
              <span className="d-flex align-center flex-vertical">
                <Title className="hero-title">Tax Notices on Autopilot</Title>
                <Text className="hero-description">
                  Automate Notice Retrieval, Centralize Notices, Track
                  Deadlines, AI Suggestions
                </Text>
              </span>
              {!idToken && <CommonButton text={LABEL_TEXT.GET_STARTED} />}
            </div>
          </div>
        </section>
        <section className="direct-tax-notice-board-feature d-flex full-width">
          <div className="direct-tax-card-container d-flex flex-vertical gap-24">
            <div className="direct-tax-card-container__wrapper">
              <h1>Income Tax Notice Board</h1>
              <CommonCards cardData={directTaxCardData} />
              <div className="mt-24">
                <ConfigProvider theme={directTaxThemeConfig}>
                  <Button
                    type="primary"
                    onClick={() => handleNavigate(ROUTES?.DIRECT_TAX)}
                  >
                    {activePlanDirectTax
                      ? LABEL_TEXT.GO_TO_INCOME_TAX
                      : LABEL_TEXT.START_FREE_TRIAL}
                  </Button>
                </ConfigProvider>
              </div>
            </div>
          </div>
          <div className="direct-tax-notice-board-feature__image">
            <picture>
              <source media="(min-width:767px)" srcSet={directGroupImage} />
              <img src={directGroupImage2} alt="Flowers" />
            </picture>
          </div>
        </section>
        <section className="indirect-tax-notice-board-feature d-flex full-width">
          <div className="indirect-tax-notice-board-feature__image">
            <picture>
              <source media="(min-width:767px)" srcSet={indirectGroupImage} />
              <img src={indirectGroupImage2} alt="Flowers" />
            </picture>
          </div>
          <div className="gst-card-container d-flex flex-vertical gap-24">
            <div className="gst-card-container__wrapper">
              <h1>GST Notice Board</h1>
              <CommonCards cardData={indirectTaxCardData} />
              <div className="mt-24">
                <ConfigProvider theme={indirectTaxThemeConfig}>
                  <Button
                    type="primary"
                    onClick={() => handleNavigate(ROUTES?.INDIRECT_TAX)}
                  >
                    {activePlanIndirectTax
                      ? LABEL_TEXT.GO_TO_GST
                      : LABEL_TEXT.START_FREE_TRIAL}
                  </Button>
                </ConfigProvider>
              </div>
            </div>
          </div>
        </section>
        <section className="container how-it-work-section d-flex justify-between gap-64">
          <div>
            <h1>How it Works</h1>
            <div className="work-content d-flex p-32 gap-24">
              <span className="text-center">
                <UsersIcon />
                <p className="m-0">
                  Sign Up /<br /> Login
                </p>
              </span>
              <span className="align-self-center">
                <Line />
              </span>
              <span className="text-center">
                <LockIcon />
                <p className=" m-0">
                  Enter client
                  <br /> details
                </p>
              </span>
              <span className="align-self-center">
                <Line />
              </span>
              <span className="text-center">
                <CloudIcon />
                <p className=" m-0">
                  Sync
                  <br /> log
                </p>
              </span>
              <span className="align-self-center">
                <Line />
              </span>
              <span className="text-center">
                <TrackNoticesIcon />
                <p className=" m-0">
                  Track your
                  <br /> notices
                </p>
              </span>
            </div>
            {!idToken && <CommonButton text={LABEL_TEXT.SIGNUP} />}
          </div>
          <div>
            <img src={desktopCalendar} width={349} height={349} />
          </div>
        </section>
        <section className="container">
          <div className="about-us-section d-flex flex-vertical">
            <h1 className="m-0">What Our Users Say About Us</h1>
            <h2 className="text-medium mb-48">
              Trusted by 1000+ CAs and Tax Practitioners across India
            </h2>
            <div className="testimonial-carousel">
              <Carousel {...settings}>
                {testimonials.map((testimonial, index) => (
                  <Card
                    key={index}
                    className="pt-20 pb-20 width-percent-50 bg-background"
                  >
                    <div className="d-flex flex-vertical gap-8">
                      <span className="d-flex align-center gap-8">
                        <QuotationIcon />
                        <Avatar
                          src={testimonial?.avatar}
                          alt={testimonial?.name}
                        />
                        <span className="d-flex flex-vertical">
                          <h3 className="m-0">{testimonial?.name}</h3>
                          <p className="m-0">{testimonial?.title}</p>
                        </span>
                      </span>
                      <div className="pl-32 pr-32">
                        <Rate disabled defaultValue={testimonial?.rating} />
                        <p className="mt-8 mb-0">{testimonial?.review}</p>
                      </div>
                    </div>
                  </Card>
                ))}
              </Carousel>
            </div>
          </div>
        </section>
        <section className="footer-section p-64">
          <div className="container">
            <CardsComponent />
          </div>
        </section>
      </div>
      <PublicCommonFooter />
    </>
  );
}

export default Home;
