import { Col, Divider, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  defaultDateFormat,
  EMPTY_STATE,
  GUTTER_VARIATIONS,
  NA_PLACEHOLDER_TEXT,
} from '../../../../common/constants';
import { LABEL_TEXT } from '../../../../common/labelTexts';
import { NoticeAndOrderDetailProps } from '../graphql/clients.type';
const { Paragraph, Text } = Typography;
dayjs.extend(relativeTime);

const NoticeOrder = ({ details }: NoticeAndOrderDetailProps) => {
  return (
    <>
      {(details?.length ?? 0) > 0 ? (
        details?.map((detail, index) => (
          <div key={index}>
            <div className="d-flex gap-24 pb-16 pt-16">
              <div className="d-flex flex-vertical gap-8 full-width">
                <Row>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      expandable: false,
                    }}
                    className="description"
                  >
                    {detail?.description}
                  </Paragraph>
                </Row>
                <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
                  <Col span={12} className="d-grid">
                    <Text className="title">
                      {LABEL_TEXT.NOTICE_REFERENCE_ID}
                    </Text>
                    <Text className="value">
                      {detail?.refId || NA_PLACEHOLDER_TEXT}
                    </Text>
                  </Col>
                  <Col span={12} className="d-grid">
                    <Text className="title">
                      {LABEL_TEXT.DATE_OF_ISSUE_OF_ORDER}
                    </Text>
                    <Text className="value text-size-12">
                      {detail?.issuanceDate
                        ? dayjs(detail?.issuanceDate).format(defaultDateFormat)
                        : NA_PLACEHOLDER_TEXT}
                    </Text>
                  </Col>
                </Row>
              </div>
            </div>
            {index < details.length - 1 && <Divider />}
          </div>
        ))
      ) : (
        <p className="empty text-center">{EMPTY_STATE?.emptyText}</p>
      )}
    </>
  );
};

export default NoticeOrder;
