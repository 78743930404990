import { useQuery } from '@apollo/client';
import { Button, Card, Form, Select, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import {
  InputMaybe,
  SortOrder,
  SyncLogsFilter,
  SyncLogsResponse,
  SyncLogsSortOn,
} from '../../../__generated__/graphql';
import { SelectDropdownIcon } from '../../../assets/svg';
import {
  defaultDateTimeFormat,
  EMPTY_STATE,
  LIMIT,
  SYNC_STATUS,
} from '../../../common/constants';
import { LABEL_TEXT } from '../../../common/labelTexts';
import CommonPagination from '../../../components/CommonPagination';
import TableComponent from '../../../components/CommonTable';
import StatusTag from '../../../components/CommonTag';
import InfiniteSelect from '../../../components/InfiniteSelect';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import { CLIENTS, SYNC_LOG } from './graphql/Queries';
const { Text } = Typography;

const initialValue = {
  filters: { status: null },
};

const SyncLog = () => {
  const [filterForm] = Form?.useForm();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [filterList, setFilterList] = useState<SyncLogsFilter>({
    limit: LIMIT,
    skip: 0,
    username: '',
  });
  const filters = Form.useWatch('filters', filterForm) ?? initialValue?.filters;

  const filter: InputMaybe<SyncLogsFilter> = useMemo(
    () => ({
      isActive: filters?.isActive && filters?.isActive === 'active',
      limit: LIMIT,
      skip: (currentPage - 1) * LIMIT,
      status: !filters?.username?.value ? null : filters?.status,
      username: filters?.username?.value ?? '',
    }),
    [filters, currentPage, filterList],
  );

  const { data: { syncLogs } = {}, loading } = useQuery(SYNC_LOG, {
    onError() {},
    skip: !filters?.username,
    variables: {
      filter,
      sort: { sortBy: SortOrder?.Desc, sortOn: SyncLogsSortOn?.UpdatedAt },
    },
    fetchPolicy: 'network-only',
  });

  const handlePagination = (current: number) => {
    setCurrentPage(current);
    setFilterList((prev) => ({ ...prev, skip: (current - 1) * LIMIT }));
  };

  const handleClear = () => {
    filterForm?.resetFields();
    setCurrentPage(1);
  };

  const columns: ColumnType<SyncLogsResponse>[] = [
    {
      title: LABEL_TEXT.PAN,
      dataIndex: 'pan',
      key: 'pan',
      fixed: 'left',
      render: (text) => text ?? '-',
    },
    {
      title: LABEL_TEXT.NAME_OF_ASSESSEE,
      dataIndex: 'assessee',
      key: 'name',
      render: (assessee) => {
        const name = assessee?.name;
        return name ?? '-';
      },
    },
    {
      title: LABEL_TEXT.LAST_DOWNLOADED,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (date) => {
        return date ? dayjs(date).format(defaultDateTimeFormat) : '-';
      },
    },
    {
      title: LABEL_TEXT.STATUS,
      dataIndex: 'status',
      key: 'status',
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: LABEL_TEXT.STATUS_MESSAGE,
      dataIndex: 'message',
      key: 'message',
      render: (text) => text ?? '-',
    },
  ];

  return (
    <div className="container">
      <div className="mt-16 mb-16">
        <MyBreadcrumb />
      </div>
      <div className="d-flex justify-between">
        <Text className="heading">{LABEL_TEXT.SYNC_LOGS}</Text>
      </div>
      <Card className="mt-16">
        <Form
          form={filterForm}
          layout="vertical"
          initialValues={initialValue}
          onValuesChange={() => setCurrentPage(1)}
        >
          <div className="d-flex align-center gap-16">
            <div className="gap-24 d-flex align-center">
              <Form.Item
                name={['filters', 'username']}
                label={LABEL_TEXT.SELECT_CLIENT}
                className="select"
              >
                <InfiniteSelect
                  query={CLIENTS}
                  variableSelector={({ skip, limit, search }) => ({
                    filter: {
                      skip,
                      limit,
                      search,
                      withName: false,
                    },
                    sort: {
                      sortBy: SortOrder.Desc,
                    },
                  })}
                  dataSelector={({ assessees }) => {
                    return (
                      assessees?.data?.map((item) => ({
                        label: (
                          <Tooltip
                            title={[item?.username, item?.name].join(' | ')}
                            placement="top"
                          >
                            {[item?.username, item?.name].join(' | ') ?? ''}
                          </Tooltip>
                        ),
                        value: item?.username ?? '',
                      })) ?? []
                    );
                  }}
                  countSelector={({ assessees }) => assessees?.count ?? 0}
                  allowSearch
                  placeholder="Pan, Username"
                  allowClear
                  onChange={(value) => {
                    if (!value) {
                      filterForm?.setFieldsValue({
                        filters: {
                          status: null,
                        },
                      });
                    }
                  }}
                  fetchPolicy="network-only"
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'status']}
                label={LABEL_TEXT.STATUS}
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  title={
                    !filters?.username?.value
                      ? 'Select client to apply status filter'
                      : ''
                  }
                  placeholder="All"
                  options={SYNC_STATUS}
                  disabled={!filters?.username?.value}
                  allowClear
                  showSearch
                />
              </Form.Item>
            </div>
            <Form.Item className="d-flex align-end align-self-end">
              <Button
                type="link"
                onClick={handleClear}
                disabled={!(filters.username || filters.status)}
              >
                {LABEL_TEXT.CLEAR_ALL}
              </Button>
            </Form.Item>
          </div>
        </Form>
        <TableComponent<SyncLogsResponse>
          rowKey="_id"
          columns={columns}
          dataSource={syncLogs?.data as SyncLogsResponse[]}
          loading={loading}
          pagination={false}
          scroll={{ x: 'max-content' }}
          locale={
            filter.username
              ? EMPTY_STATE
              : {
                  emptyText: 'Please Select Client',
                }
          }
        />
        {syncLogs?.count ? (
          <CommonPagination
            count={syncLogs?.count}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        ) : null}
      </Card>
    </div>
  );
};

export default SyncLog;
