import { Card, Col, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { GUTTER_VARIATIONS } from '../../../../common/constants';
import { LABEL_TEXT } from '../../../../common/labelTexts';
import StatusTag from '../../../../components/CommonTag';
import { ProfileDetailsCardProps } from '../../companies/graphql/clients.type';

dayjs.extend(relativeTime);
const { Text } = Typography;

const ProfileDetailCard = ({
  caseId,
  gstInNumber,
  applicationDate,
  status,
}: ProfileDetailsCardProps) => {
  return (
    <Card className="mt-16 mb-24 p-8">
      <Row gutter={[GUTTER_VARIATIONS, GUTTER_VARIATIONS]}>
        <Col xs={6} xl={6} className="d-grid">
          <Text className="title">{LABEL_TEXT.CASE_ID}</Text>
          <Text className="value">{caseId}</Text>
        </Col>
        <Col xs={6} xl={6} className="d-grid">
          <Text className="title">{LABEL_TEXT.GSTIN_UIN_TEMPORARY_ID}</Text>
          <Text className="value">{gstInNumber}</Text>
        </Col>
        <Col xs={6} xl={6} className="d-grid">
          <Text className="title">{LABEL_TEXT.APPLICATION_DATE}</Text>
          <Text className="value">{applicationDate}</Text>
        </Col>
        <Col xs={6} xl={6} className="d-grid">
          <Text className="title">{LABEL_TEXT.STATUS}</Text>
          <span>
            <StatusTag status={status} />
          </span>
        </Col>
      </Row>
    </Card>
  );
};

export default ProfileDetailCard;
