import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Card, Form, Select, Space, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { omit } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  InputMaybe,
  Itr,
  ItrListFilter,
  SortOrder,
} from '../../../__generated__/graphql';
import { Export, EyeIcon, SelectDropdownIcon } from '../../../assets/svg';
import axiosInstance from '../../../common/axiosInstance';
import {
  defaultDateFormat,
  EMPTY_STATE,
  FILING_TYPE,
  ITR_FILL_START_YEAR,
  LIMIT,
} from '../../../common/constants';
import { LABEL_TEXT } from '../../../common/labelTexts';
import { downloadCsv, generateYearOptions } from '../../../common/utils';
import CommonPagination from '../../../components/CommonPagination';
import CommonSearch from '../../../components/CommonSearch';
import TableComponent from '../../../components/CommonTable';
import InfiniteSelect from '../../../components/InfiniteSelect';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import useRouter from '../../../hooks/useRouter';
import { ASSESSEE } from '../clients/graphql/Queries';
import { ASSESSEES_LIST, ITR_FILED_RETURN_LIST } from './graphql/Queries';
const { Text } = Typography;

const initialFilters = {
  search: '',
  skip: 0,
  limit: LIMIT,
};

const initialValue = {
  filters: {
    assessmentYear: null,
    assessee: null,
    filingType: null,
  },
};

const FiledReturn = () => {
  const [filterForm] = Form.useForm();
  const {
    navigate,
    params: { id },
  } = useRouter();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [listFilter, setListFilter] = useState(initialFilters);
  const [searchParams] = useSearchParams();

  const clientLabel = searchParams.get('clientLabel');
  const client = searchParams.get('client');

  const [getAssesseeDetailsCall] = useLazyQuery(ASSESSEE, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const filters = Form.useWatch('filters', filterForm) ?? initialValue.filters;
  const handlePagination = (current: number) => {
    setCurrentPage(current);
  };

  const handleClear = () => {
    if (id) {
      const getAssesseeValue = filterForm.getFieldValue('filters');
      filterForm.setFieldsValue({
        filters: {
          ...initialValue.filters,
          assessee: getAssesseeValue.assessee,
        },
      });
    } else {
      filterForm?.resetFields();
      setCurrentPage(1);
    }
  };

  const filter: InputMaybe<ItrListFilter> = useMemo(
    () => ({
      assesseeId: filters?.assessee?.value ?? '',
      skip: (currentPage - 1) * LIMIT,
      search: listFilter.search?.trim(),
      limit: listFilter.limit,
      filingType: filters?.filingType,
      assessmentYear: filters?.assessmentYear,
    }),
    [filters, listFilter, currentPage],
  );

  const { data: { itrList } = {}, loading } = useQuery(ITR_FILED_RETURN_LIST, {
    variables: {
      filter,
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const exportCsv = async () => {
    const response = await axiosInstance.post('/v1/itr/export-csv', {
      filter: {
        ...omit(filter, ['limit', 'skip']),
      },
    });
    downloadCsv(response);
  };

  useEffect(() => {
    if (id) {
      getAssesseeDetailsCall({
        variables: { where: { id: id } },
        onCompleted: ({ assessee }) => {
          filterForm.setFieldsValue({
            filters: {
              ...filters,
              assessee: {
                value: assessee?._id ?? '',
                label: `${assessee?.username ?? ''}|${assessee?.name ?? ''}`,
              },
            },
          });
        },
      });
    }
  }, []);

  const handleResponse = (record: Itr) => {
    navigate(`${record?._id}/status?response=true`);
  };

  const columns: ColumnType<Itr>[] = [
    {
      title: LABEL_TEXT.INDEX,
      dataIndex: 'key',
      key: 'key',
      fixed: 'left',
      render: (_val, _, i) => i + 1 + LIMIT * (currentPage - 1),
    },
    {
      title: LABEL_TEXT.PAN,
      dataIndex: ['assessee', 'username'],
      key: 'pan',
      render: (text) => text ?? '-',
    },
    {
      title: LABEL_TEXT.FULL_ASSESSMENT_YEAR,
      dataIndex: 'assessmentYear',
      key: 'assessmentYear',
      fixed: 'left',
      render: (text) => text ?? '-',
    },
    {
      title: LABEL_TEXT.FILING_TYPE,
      dataIndex: 'filingType',
      key: 'filingType',
      render: (text) => text ?? '-',
    },
    {
      title: LABEL_TEXT.ACKNOWLEDGEMENT_NO,
      dataIndex: 'acknowledgmentNo',
      key: 'acknowledgmentNo',
      width: 'auto',
      render: (text) => text ?? '-',
    },
    {
      title: LABEL_TEXT.FILED_BY,
      dataIndex: 'filedBy',
      key: 'filedBy',
      render: (text) => text ?? '-',
    },
    {
      title: LABEL_TEXT.FILING_DATE,
      dataIndex: 'filingDate',
      key: 'filingDate',
      render: (date) => {
        return date ? dayjs(date).format(defaultDateFormat) : '-';
      },
    },
    {
      title: LABEL_TEXT.ACTION,
      fixed: 'right',
      render: (record) => (
        <Space>
          <span onClick={() => handleResponse(record)} className="pointer">
            <EyeIcon key="view" />
          </span>
        </Space>
      ),
    },
  ];

  return (
    <div className="container">
      <div className="mt-16 mb-16">
        <MyBreadcrumb
          username={
            clientLabel ?? (itrList?.data?.[0]?.assessee?.username || '')
          }
          id={itrList?.data?.[0]?.assessee?._id ?? (client || '')}
          assesseeId={itrList?.data?.[0]?.assessee?._id ?? (client || '')}
        />
      </div>
      <div className="d-flex justify-between align-center">
        <Text className="heading">{LABEL_TEXT.ITR_FILED_RETURN}</Text>
        <CommonSearch
          handleChangeSearch={(val) => {
            setListFilter((prev) => ({ ...prev, search: val }));
            setCurrentPage(1);
          }}
          iconPlacement="right"
          allowClear
        />
      </div>
      <div className="d-flex justify-between mt-16">
        <Button
          type="primary"
          className="mr-8 d-flex align-center"
          icon={<Export />}
          onClick={() => exportCsv()}
        >
          {LABEL_TEXT.EXPORT_TO_CSV}
        </Button>
      </div>
      <Card className="mt-16">
        <div className="d-flex flex-vertical gap-16">
          <Form
            form={filterForm}
            layout="vertical"
            onValuesChange={() => setCurrentPage(1)}
            initialValues={initialValue}
            className="filter-form"
          >
            <div className="filters d-flex align-center flex-wrap gap-24">
              <Form.Item
                name={['filters', 'assessee']}
                label={LABEL_TEXT.SELECT_CLIENT}
                className="select"
              >
                <InfiniteSelect
                  query={ASSESSEES_LIST}
                  variableSelector={({ skip, limit, search }) => ({
                    filter: {
                      skip,
                      limit,
                      search,
                      withName: false,
                    },
                    sort: {
                      sortBy: SortOrder.Desc,
                    },
                  })}
                  dataSelector={({ assessees }) => {
                    return (
                      assessees?.data?.map((item) => ({
                        label: (
                          <Tooltip
                            title={[item?.username, item?.name].join(' | ')}
                            placement="top"
                          >
                            {[item?.username, item?.name].join(' | ') ?? ''}
                          </Tooltip>
                        ),
                        value: item?._id ?? '',
                      })) ?? []
                    );
                  }}
                  countSelector={({ assessees }) => assessees?.count ?? 0}
                  allowSearch
                  placeholder="Pan, Username"
                  allowClear
                  disabled={!!id}
                  fetchPolicy="network-only"
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'assessmentYear']}
                label={LABEL_TEXT.FULL_ASSESSMENT_YEAR}
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={generateYearOptions(ITR_FILL_START_YEAR)}
                  allowClear
                  showSearch
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'filingType']}
                label={LABEL_TEXT.FILING_TYPE}
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={FILING_TYPE}
                  allowClear
                />
              </Form.Item>
              <Form.Item className="d-flex align-end align-self-end">
                <Button
                  type="link"
                  onClick={handleClear}
                  disabled={
                    !(
                      (id ? !filters?.assessee : filters?.assessee) ||
                      filters.assessmentYear ||
                      filters.filingType
                    )
                  }
                >
                  {LABEL_TEXT.CLEAR_ALL}
                </Button>
              </Form.Item>
            </div>
          </Form>
          <TableComponent<Itr>
            columns={columns}
            dataSource={itrList?.data as Itr[]}
            pagination={false}
            scroll={{ x: 'max-content' }}
            loading={loading}
            locale={EMPTY_STATE}
          />
        </div>
        {itrList?.count ? (
          <CommonPagination
            count={itrList?.count}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        ) : null}
      </Card>
    </div>
  );
};

export default FiledReturn;
