import { useMutation } from '@apollo/client';
import { Button, Form, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { CompanyCreateInput } from '../../../../__generated__/graphql';
import { SelectDropdownIcon } from '../../../../assets/svg';
import {
  ACTIVATION,
  ACTIVE_STATUS,
  MESSAGE,
} from '../../../../common/constants';
import { LABEL_TEXT } from '../../../../common/labelTexts';
import { formValidatorRules } from '../../../../common/utils';
import CommonModal from '../../../../components/modals/CommonModal';
import { CreateModalType } from '../graphql/clients.type';
import { CREATE_COMPANY, UPDATE_COMPANY } from '../graphql/Mutation';
const { required } = formValidatorRules;

const CompanyModal = ({
  open,
  footer,
  closable,
  onCancel,
  editData,
  onSuccess = () => {},
  onError = () => {},
}: CreateModalType) => {
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [createCompany, { loading: loadAddCompany }] = useMutation(
    CREATE_COMPANY,
    {
      onError(error) {
        if (error) {
          onError(error?.message as string);
        }
      },
      onCompleted: (res) => {
        if (res) {
          form?.resetFields();
          onCancel();
          onSuccess(res?.createCompany?.message as string);
        }
      },
    },
  );
  const [updateCompany, { loading: loadUpdateCompany }] = useMutation(
    UPDATE_COMPANY,
    {
      onError(error) {
        if (error) {
          onError(error?.message as string);
        }
      },
      onCompleted: (res) => {
        if (res) {
          form?.resetFields();
          onCancel();
          onSuccess(res?.updateCompany?.message as string);
        }
      },
    },
  );

  useEffect(() => {
    if (editData) {
      form?.setFieldsValue({
        isActive: editData?.isActive
          ? ACTIVATION?.ACTIVE
          : ACTIVATION?.INACTIVE,
        username: editData?.username,
      });
    }
  }, [editData, form]);

  const checkFormValid = () => {
    const fieldsError = form?.getFieldsError();
    const hasErrors = fieldsError?.some(({ errors }) => errors.length > 0);
    const allFieldsTouched = form?.isFieldsTouched(true);
    setIsFormValid(!hasErrors && allFieldsTouched);
  };

  const onFinish = async (values: CompanyCreateInput) => {
    if (editData?._id) {
      await updateCompany({
        variables: {
          data: {
            isActive: status,
            password: values?.password,
          },
          where: { id: editData._id },
        },
      });
    } else {
      await createCompany({
        variables: {
          data: { username: values?.username, password: values?.password },
        },
      });
    }
  };
  return (
    <>
      <CommonModal
        open={open}
        title={
          editData?._id ? LABEL_TEXT.UPDATE_COMPANY : LABEL_TEXT.ADD_COMPANY
        }
        footer={footer}
        closable={closable}
        onCancel={onCancel}
        maskClosable={false}
        centered={true}
      >
        <Form
          form={form}
          layout="vertical"
          preserve={false}
          onFinish={onFinish}
          onFieldsChange={checkFormValid}
        >
          <Form.Item
            label={LABEL_TEXT.GST_USERNAME}
            name="username"
            rules={[{ ...required, message: MESSAGE?.required }]}
          >
            <Input
              placeholder="Enter Username"
              autoComplete="new-password"
              disabled={!!editData?._id}
            />
          </Form.Item>
          <Form.Item
            label={LABEL_TEXT.GST_PASSWORD}
            name="password"
            rules={[
              { ...required, message: MESSAGE?.required, whitespace: true },
            ]}
          >
            <Input.Password
              placeholder="Enter Password"
              autoComplete="new-password"
            />
          </Form.Item>
          {editData?._id && (
            <>
              <Form.Item
                label={LABEL_TEXT.STATUS}
                name="isActive"
                rules={[
                  { ...required, message: MESSAGE?.required, whitespace: true },
                ]}
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  options={ACTIVE_STATUS}
                  placeholder="Change status"
                  onChange={(value) => setStatus(value === 'active')}
                />
              </Form.Item>
            </>
          )}
          <Button
            type="primary"
            htmlType="submit"
            className="full-width"
            disabled={!isFormValid}
            loading={loadAddCompany || loadUpdateCompany}
          >
            {LABEL_TEXT.SUBMIT}
          </Button>
        </Form>
      </CommonModal>
    </>
  );
};

export default CompanyModal;
