import { Typography } from 'antd';
import { CardData } from '../../../types/common.type';
const { Title } = Typography;

const CommonCards = ({ cardData }: { cardData: CardData[] }) => {
  return (
    <div className="cards-container">
      {cardData?.map((card, index) => (
        <div key={index} className="card-content d-flex gap-8 flex-vertical">
          {card?.icon}
          <Title level={4} className="m-0 mb-0">
            {card?.title}
          </Title>
          <p className="text-medium m-0">{card?.description}</p>
        </div>
      ))}
    </div>
  );
};

export default CommonCards;
