import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
} from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import dayjs from 'dayjs';
import { isEmpty, omit } from 'lodash';
import { Key, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  AdditionalNotice,
  AdditionalNoticesSort,
  AdditionalNoticesSortOn,
  AdditionalNoticeStatus,
  AdditionalNoticesType,
  Attachment,
  Company,
  DurationType,
  SortOrder,
} from '../../../../__generated__/graphql';
import {
  Export,
  EyeIcon,
  FilePDF,
  Icon,
  Minus,
  Pencil,
  SelectDropdownIcon,
} from '../../../../assets/svg';
import axiosInstance from '../../../../common/axiosInstance';
import {
  ADDITIONAL_NOTICE_STATUS_OPTIONS,
  ADDITIONAL_NOTICE_TYPE,
  AI_TOOLTIP_MESSAGE,
  color,
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  DURATION_TYPE,
  EMPTY_STATE,
  LIMIT,
  MESSAGE,
  ROUTES,
} from '../../../../common/constants';
import useQueryValue from '../../../../common/hooks/useQueryValue';
import { LABEL_TEXT } from '../../../../common/labelTexts';
import {
  disabledYear,
  downloadCsv,
  formValidatorRules,
} from '../../../../common/utils';
import CommonPagination from '../../../../components/CommonPagination';
import CommonSearch from '../../../../components/CommonSearch';
import TableComponent from '../../../../components/CommonTable';
import StatusTag from '../../../../components/CommonTag';
import InfiniteSelect from '../../../../components/InfiniteSelect';
import AttachmentModal from '../../../../components/modals/AttachmentModal';
import CommonModal from '../../../../components/modals/CommonModal';
import useRouter from '../../../../hooks/useRouter';
import {
  SelectedRowKeysState,
  UpdateStatus,
} from '../../../../types/common.type';
import { COMPANIES, COMPANY } from '../../companies/graphql/Queries';
import { UPDATE_ADDITIONAL_NOTICES_STATUS } from '../../dashboard/graphql/Mutation';
import {
  ADDITIONAL_NOTICES_ORDERS_LIST,
  SECTION_LIST,
} from '../graphql/Queries';
import { PROPS_TYPE } from '../Notice';
const { RangePicker } = DatePicker;
const { required } = formValidatorRules;

const initialFilters = {
  search: '',
  skip: 0,
  limit: LIMIT,
};

const fieldToParams: Record<string, string> = {
  businessName: COMMON_QUERY_PARAMS.SORT_BUSINESS_NAME,
  issuanceDate: COMMON_QUERY_PARAMS.SORT_ISSUANCE_DATE,
  replyDueDate: COMMON_QUERY_PARAMS.SORT_REPLY_DUE_DATE,
  status: COMMON_QUERY_PARAMS.SORT_STATUS,
};

const NoticeOrder = ({ count, setCount }: PROPS_TYPE) => {
  const [filterForm] = Form.useForm();
  const [statusForm] = Form?.useForm();
  const { navigate } = useRouter();
  const { params } = useRouter();
  const { id } = params;
  const [searchParams] = useSearchParams();
  const urlDataObj = useQueryValue();
  const currentPage = urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]
    ? Number(urlDataObj?.[COMMON_QUERY_PARAMS.PAGE])
    : 1;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [companiesData, setCompaniesData] = useState<Company | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<SelectedRowKeysState>(
    {},
  );
  const companyId = urlDataObj?.[COMMON_QUERY_PARAMS.COMPANY_ID];
  const gstNumber =
    searchParams.get(COMMON_QUERY_PARAMS.CLIENT_LABEL) ??
    companiesData?.gstNumber;
  const tradeName =
    searchParams.get(COMMON_QUERY_PARAMS.NAME) ?? companiesData?.tradeName;
  const [isTableSorterChange, setIsTableSorterChange] =
    useState<boolean>(false);
  const [sectionOptions, setSectionOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [attachments, setAttachment] = useState<Attachment[]>([]);
  const [search, setSearch] = useState<string>('');
  const updateBulkStatus = Form.useWatch('statusForm', statusForm);
  const askAI = process.env.REACT_APP_ASK_AI_REDIRECT_URL!;
  const totalSelectedRows = Object?.values(selectedRowKeys)?.flat();

  const [getSections] = useLazyQuery(SECTION_LIST, {
    onCompleted: (res) => {
      if (res?.additionalNoticeSections?.length ?? 0 > 0) {
        const prepareOptions = res?.additionalNoticeSections?.map((item) => ({
          label: item,
          value: item,
        }));
        setSectionOptions(
          prepareOptions as {
            value: string;
            label: string;
          }[],
        );
      }
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    getSections();
  }, []);

  const cancelModal = () => {
    setIsModalOpen(false);
    statusForm.resetFields();
  };
  const initialValue = {
    filters: {
      companyId:
        (id || companyId) && (gstNumber || tradeName)
          ? {
              value: id,
              label:
                gstNumber && !tradeName
                  ? gstNumber
                  : [gstNumber, tradeName].filter(Boolean).join(' | ') || null,
            }
          : null,
      duration:
        (urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] as DurationType) || null,
      ids: [],
      type: (urlDataObj?.[COMMON_QUERY_PARAMS.NOTICE_TYPE] ||
        null) as AdditionalNoticesType,
      ...(urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] ===
        DurationType.Custom && {
        period: [
          urlDataObj?.[COMMON_QUERY_PARAMS.START]
            ? dayjs(
                urlDataObj?.[COMMON_QUERY_PARAMS.START],
                defaultDateFormat,
              ).startOf('day')
            : '',
          urlDataObj?.[COMMON_QUERY_PARAMS.END]
            ? dayjs(
                urlDataObj?.[COMMON_QUERY_PARAMS.END],
                defaultDateFormat,
              ).endOf('day')
            : '',
        ],
      }),
      limit: Number(urlDataObj?.[COMMON_QUERY_PARAMS.LIMIT]) || LIMIT,
      skip: urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]
        ? (Number(urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]) - 1) * LIMIT
        : 0,
      status:
        (urlDataObj?.[COMMON_QUERY_PARAMS.STATUS] as AdditionalNoticeStatus) ||
        null,
      search: '',
      section: urlDataObj?.[COMMON_QUERY_PARAMS.SECTION] || null,
    },
  };
  const filters = initialValue.filters;
  filterForm.setFieldsValue({ filters: filters });

  const prepareQueryVariableFromUrl = useMemo(() => {
    let prepareSorterFromURL: {
      sortBy: SortOrder;
      sortOn: AdditionalNoticesSortOn;
    }[] = [];
    Object.keys(fieldToParams).forEach((item) => {
      if (searchParams.has(fieldToParams?.[item as string])) {
        const value = searchParams.get(fieldToParams?.[item as string]);
        prepareSorterFromURL.push({
          sortBy: value as SortOrder,
          sortOn: item as AdditionalNoticesSortOn,
        });
      }
    });
    if (prepareSorterFromURL.length === 0) {
      prepareSorterFromURL = isTableSorterChange
        ? [
            {
              sortBy: SortOrder.Desc,
              sortOn: AdditionalNoticesSortOn.CreatedAt,
            },
          ]
        : [
            {
              sortBy: SortOrder.Asc,
              sortOn: AdditionalNoticesSortOn.BusinessName,
            },
          ];
    } else if (
      isTableSorterChange &&
      !prepareSorterFromURL.some(
        (sorter) => sorter.sortOn === AdditionalNoticesSortOn.BusinessName,
      )
    ) {
      prepareSorterFromURL.push({
        sortBy: SortOrder.Desc,
        sortOn: AdditionalNoticesSortOn.CreatedAt,
      });
    }

    return {
      filter: {
        companyId: companyId || id || null,
        duration:
          (urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] as DurationType) || null,
        ids: [],
        ...(urlDataObj?.[COMMON_QUERY_PARAMS.IS_ISSUED_ON] && {
          isIssuedOn: !urlDataObj?.[COMMON_QUERY_PARAMS.IS_ISSUED_ON],
        }),
        type:
          urlDataObj?.[COMMON_QUERY_PARAMS.NOTICE_TYPE] ||
          AdditionalNoticesType.All,
        ...(urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] ===
          DurationType.Custom && {
          period: {
            start: urlDataObj?.[COMMON_QUERY_PARAMS.START]
              ? dayjs(
                  urlDataObj?.[COMMON_QUERY_PARAMS.START],
                  defaultDateFormat,
                ).startOf('day')
              : '',
            end: urlDataObj?.[COMMON_QUERY_PARAMS.END]
              ? dayjs(
                  urlDataObj?.[COMMON_QUERY_PARAMS.END],
                  defaultDateFormat,
                ).endOf('day')
              : '',
          },
        }),
        limit: Number(urlDataObj?.[COMMON_QUERY_PARAMS.LIMIT]) || LIMIT,
        skip: urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]
          ? (Number(urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]) - 1) * LIMIT
          : 0,
        status:
          (urlDataObj?.[
            COMMON_QUERY_PARAMS.STATUS
          ] as AdditionalNoticeStatus) || null,
        search: search,
        section: urlDataObj?.[COMMON_QUERY_PARAMS.SECTION] || '',
      },
      sort: prepareSorterFromURL as AdditionalNoticesSort[],
    };
  }, [location.search, isTableSorterChange, search]);

  const handlePagination = (page: number) => {
    searchParams.set(COMMON_QUERY_PARAMS.PAGE, page.toString());
    searchParams.set(COMMON_QUERY_PARAMS.LIMIT, LIMIT.toString());
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const resetPagination = () => {
    searchParams.delete(COMMON_QUERY_PARAMS.PAGE);
    searchParams.delete(COMMON_QUERY_PARAMS.LIMIT);
  };

  /* any used due to type incompatibility */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateRangeNavigation = (date: any) => {
    if (date && date.length > 0) {
      const startDate = dayjs(date[0]).format(defaultDateFormat);
      const endDate = dayjs(date[1]).format(defaultDateFormat);
      searchParams.set(COMMON_QUERY_PARAMS.START, startDate);
      searchParams.set(COMMON_QUERY_PARAMS.END, endDate);
      searchParams.delete(COMMON_QUERY_PARAMS.IS_ISSUED_ON);
    } else {
      if (
        searchParams.has(COMMON_QUERY_PARAMS.START) ||
        searchParams.has(COMMON_QUERY_PARAMS.END) ||
        searchParams.has(COMMON_QUERY_PARAMS.IS_ISSUED_ON)
      ) {
        searchParams.delete(COMMON_QUERY_PARAMS.START);
        searchParams.delete(COMMON_QUERY_PARAMS.END);
        searchParams.delete(COMMON_QUERY_PARAMS.IS_ISSUED_ON);
      }
    }
    resetPagination();
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const handleClear = () => {
    navigate(`${location?.pathname}`, {
      replace: true,
    });
  };

  const getSorter = (fieldName: string) => {
    let order = null;
    if (prepareQueryVariableFromUrl?.sort?.length > 0) {
      prepareQueryVariableFromUrl?.sort?.find((item) => {
        if (item?.sortOn === fieldName) {
          order = item?.sortBy === SortOrder.Asc ? 'ascend' : 'descend';
        }
      });
    }
    return order;
  };

  const {
    data: { additionalNotices = {} } = {},
    loading,
    refetch,
  } = useQuery(ADDITIONAL_NOTICES_ORDERS_LIST, {
    variables: prepareQueryVariableFromUrl,
    onCompleted: ({ additionalNotices }) => {
      setCount({
        ...count,
        additionalNoticeCount: additionalNotices?.count ?? 0,
      });
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [getCompanies] = useLazyQuery(COMPANY, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (id) {
      getCompanies({
        variables: {
          where: { id: id },
        },
        onCompleted: (response) => {
          if (response) {
            setCompaniesData(response?.company as Company);
            filterForm.setFieldsValue({
              filters: {
                companyId: {
                  label: `${response?.company?.gstNumber ?? ''} | ${response?.company?.tradeName ?? ''}`,
                  value: id,
                },
              },
            });
          }
        },
      });
    }
  }, [id]);

  const rowSelection: TableProps<AdditionalNotice>['rowSelection'] = {
    selectedRowKeys: selectedRowKeys?.[currentPage],
    onChange: (newSelectedRowKeys: Key[]) => {
      setSelectedRowKeys((prev) => ({
        ...prev,
        [currentPage]: newSelectedRowKeys,
      }));
    },
    ...(selectedRowKeys[currentPage]?.length && {
      columnTitle: () => (
        <span
          className="d-flex pointer"
          onClick={() => {
            setSelectedRowKeys((prev) => ({
              ...prev,
              [currentPage]: [],
            }));
          }}
        >
          <Minus />
        </span>
      ),
    }),
  };

  const handleFieldsNavigation = (
    fieldType: string,
    value: string | null,
    additionalParams?: Record<string, string | null>,
  ) => {
    resetPagination();
    if (value) {
      searchParams.set(fieldType, value as string);
    } else {
      if (searchParams.has(fieldType)) {
        searchParams.delete(fieldType);
      }
    }
    if (additionalParams) {
      Object.entries(additionalParams).forEach(([key, val]) => {
        if (val) {
          searchParams.set(key, val);
        } else if (searchParams.has(key)) {
          searchParams.delete(key);
        }
      });
    }
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const exportCsv = async () => {
    const response = await axiosInstance.post(
      '/v1/additional-notice/export-csv',
      {
        filter: {
          ...omit(prepareQueryVariableFromUrl.filter, ['limit', 'skip']),
          ids: totalSelectedRows,
        },
        sort: prepareQueryVariableFromUrl?.sort,
      },
    );
    downloadCsv(response);
    setSelectedRowKeys({});
  };

  const handleDurationNavigation = (e: string) => {
    if (
      searchParams.has(COMMON_QUERY_PARAMS.START) ||
      searchParams.has(COMMON_QUERY_PARAMS.END)
    ) {
      searchParams.delete(COMMON_QUERY_PARAMS.START);
      searchParams.delete(COMMON_QUERY_PARAMS.END);
    }
    resetPagination();
    handleFieldsNavigation(COMMON_QUERY_PARAMS.DURATION, e);
  };

  const [updateStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_ADDITIONAL_NOTICES_STATUS,
    {
      onCompleted: () => {
        refetch(prepareQueryVariableFromUrl);
        statusForm.resetFields();
        setIsModalOpen(false);
        setSelectedRowKeys({});
        resetPagination();
        navigate(`${location?.pathname}?${searchParams.toString()}`, {
          replace: true,
        });
      },
    },
  );

  const updateStateForm = (value: UpdateStatus) => {
    updateStatus({
      variables: {
        where: {
          ids: value?.statusForm?.ids ?? [],
        },
        data: {
          status: value?.statusForm?.status as AdditionalNoticeStatus,
          remarks: value?.statusForm?.remarks ?? '',
        },
      },
    });
  };

  const handleTableChange: TableProps['onChange'] = (_, _filters, sorter) => {
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    Object.values(fieldToParams).forEach((item) => {
      if (searchParams.has(item as string)) {
        searchParams.delete(item);
      }
    });
    if (sorterAry && Array.isArray(sorterAry)) {
      if (!isTableSorterChange) {
        if (
          sorterAry?.find(
            (sorter) =>
              sorter?.field === AdditionalNoticesSortOn.BusinessName &&
              sorter?.order === 'ascend',
          )
        ) {
          sorterAry.splice(0, 1);
        }
        setIsTableSorterChange(true);
      }

      sorterAry.forEach((item) => {
        const field = item.field as keyof typeof fieldToParams;
        if (item?.order) {
          searchParams.set(
            fieldToParams[field],
            item?.order === 'descend' ? SortOrder.Desc : SortOrder.Asc,
          );
        }
      });
      navigate(`${location?.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  };

  const handleAttachment = (item: AdditionalNotice) => {
    if ((item?.attachments?.length ?? 0) > 0) {
      setOpenModal(true);
      setAttachment(item?.attachments as Attachment[]);
    }
  };

  const columns: ColumnType<AdditionalNotice>[] = [
    {
      title: LABEL_TEXT.GSTIN,
      dataIndex: 'gstNumber',
      key: '_id',
      fixed: 'left',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              const queryParams = new URLSearchParams();
              if (record?._id) {
                queryParams.append(COMMON_QUERY_PARAMS?.NOTICE_ID, record._id);
              }
              if (record?.isAck === true) {
                queryParams.append(COMMON_QUERY_PARAMS?.IS_ACK, 'true');
              }

              navigate(`${ROUTES?.DETAIL}?${queryParams.toString()}`);
            }}
          >
            {record?.company?.gstNumber}
          </a>
        );
      },
    },
    {
      title: LABEL_TEXT.LEGAL_NAME,
      dataIndex: 'businessName',
      key: 'businessName',
      fixed: 'left',
      render: (_, record) => {
        return record?.company?.businessName ?? '-';
      },
      sorter: { multiple: 4 },
      sortOrder: getSorter('businessName'),
    },
    {
      title: LABEL_TEXT.TRADE_NAME,
      dataIndex: 'tradeName',
      key: 'tradeName',
      render: (_, record) => {
        return record?.company?.tradeName;
      },
    },
    {
      title: LABEL_TEXT.SECTION_NO,
      dataIndex: 'sectionNo',
      key: 'sectionNo',
      render: (text) => {
        return text ?? '-';
      },
    },
    {
      title: LABEL_TEXT.REFERENCE_ID,
      dataIndex: 'refId',
      key: 'refId',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.TYPE,
      dataIndex: 'type',
      key: 'type',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.ISSUED_DATE,
      dataIndex: 'issuanceDate',
      key: 'issuanceDate',
      sorter: { multiple: 1 },
      sortOrder: getSorter('issuanceDate'),
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: LABEL_TEXT.DUE_DATE,
      dataIndex: 'replyDueDate',
      key: 'replyDueDate',
      sortOrder: getSorter('replyDueDate'),
      sorter: { multiple: 2 },
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: LABEL_TEXT.REPLY_FILED_AGAINST,
      dataIndex: 'repliedAgainstFiled',
      key: '_id',
      render: (_, item) =>
        item?.replies && item?.replies?.length > 0
          ? item?.replies?.[0]?.repliedAgainstFiled
          : '-',
    },
    {
      title: LABEL_TEXT.REPLY_DATE,
      dataIndex: 'replyDueDate',
      key: '_id',
      render: (_, item) =>
        item?.replies && item?.replies?.length > 0
          ? dayjs(item?.replies?.[0]?.replyDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: LABEL_TEXT.ORDER_NO,
      key: '_id',
      render: (_, item) => (item?.section === 'ORDER' ? item?.refId : '-'),
    },
    {
      title: LABEL_TEXT.ORDER_DATE,
      key: '_id',
      render: (_, item) =>
        item?.section === 'ORDER'
          ? dayjs(item.issuanceDate).format(defaultDateFormat)
          : '-',
    },
    /* kept for future use */
    /*  {
      title: LABEL_TEXT.SUBMISSION_AMOUNT,
      dataIndex: 'issuanceDate',
      key: '_id',
      render: (amount: string) => (amount ? amount : '-'),
    },
    {
      title: LABEL_TEXT.SUBMISSION_DATE,
      dataIndex: 'issuanceDate',
      key: '_id',
      render: (amount: string) => (amount ? amount : '-'),
    }, */
    {
      title: LABEL_TEXT.REMARKS,
      dataIndex: 'remarks',
      key: 'remarks',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.NOTICE_ORDER_DESCRIPTION,
      dataIndex: 'description',
      key: 'description',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.NOTICE_TYPE,
      dataIndex: 'section',
      key: '_id',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.STATUS,
      dataIndex: 'status',
      fixed: 'right',
      key: 'status',
      sorter: { multiple: 3 },
      sortOrder: getSorter('status'),
      render: (status) => <StatusTag status={status} />,
    },
    {
      title: LABEL_TEXT.ACTION,
      fixed: 'right',
      render: (record) => {
        const pdf = record?.attachments?.[0]?.url;
        return (
          <Space>
            <span
              key="file"
              onClick={() => handleAttachment(record)}
              className={`${!pdf ? 'pointer-not-allowed' : 'pointer'}`}
            >
              <FilePDF {...(!pdf && { color: '#C6C6C6' })} />
            </span>
            <span
              key="edit"
              onClick={() => {
                setIsModalOpen(true);
                statusForm.setFieldsValue({
                  statusForm: {
                    status: record?.status,
                    ids: [record?._id],
                    remarks: record?.remarks,
                  },
                });
              }}
              className="pointer"
            >
              <Pencil color={color} />
            </span>
            <span
              className="pointer"
              onClick={() => {
                const queryParams = new URLSearchParams({
                  [COMMON_QUERY_PARAMS?.NOTICE_ID]: record?._id,
                });
                if (record?.isAck === true) {
                  queryParams.append(COMMON_QUERY_PARAMS?.IS_ACK, 'true');
                }
                navigate(`${ROUTES?.DETAIL}?${queryParams.toString()}`);
              }}
            >
              <EyeIcon key="view" color={color} />
            </span>

            <Tooltip placement="top" title={AI_TOOLTIP_MESSAGE}>
              <Button
                type="default"
                icon={<Icon color={color} />}
                className="d-flex align-center ask-ai-btn pointer p-0"
                onClick={() => window?.open(askAI)}
              >
                {LABEL_TEXT.ASK_AI}
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  return (
    <div className="full-width">
      <div className="button-container">
        <div className="left-buttons gap-16">
          <Button
            type="default"
            onClick={async () => {
              setIsModalOpen(true);
              statusForm.setFieldsValue({
                statusForm: {
                  ids: totalSelectedRows,
                },
              });
            }}
            disabled={!(totalSelectedRows.length > 0)}
          >
            {LABEL_TEXT.BULK_UPDATE_STATUS}
          </Button>
          <Button
            type="default"
            icon={<Export color="#04724D" />}
            onClick={exportCsv}
          >
            {LABEL_TEXT.EXPORT_TO_CSV}
          </Button>
        </div>
        <div className="right-buttons gap-16">
          <CommonSearch
            handleChangeSearch={(val) => {
              setSearch(val);
            }}
            iconPlacement="right"
            allowClear
            defaultValue={prepareQueryVariableFromUrl?.filter?.search || ''}
          />
        </div>
      </div>
      <Card className="table-card mt-16">
        <div className="d-flex flex-vertical gap-16">
          <Form
            form={filterForm}
            layout="vertical"
            initialValues={initialFilters}
            className="filter-form"
          >
            <div className="filters d-flex align-center gap-8">
              <Form.Item
                name={['filters', 'companyId']}
                label={LABEL_TEXT.SELECT_CLIENT}
                className="select"
              >
                <InfiniteSelect
                  query={COMPANIES}
                  fetchPolicy="network-only"
                  variableSelector={({ skip, limit, search }) => ({
                    filter: {
                      skip,
                      limit,
                      search,
                      withTradeName: false,
                    },
                    sort: {
                      sortBy: SortOrder.Desc,
                    },
                  })}
                  dataSelector={({ companies }) => {
                    return (
                      companies?.data?.map((item) => {
                        const values =
                          [
                            item?.gstNumber,
                            item?.tradeName === 'NA'
                              ? item?.businessName
                              : item?.tradeName,
                          ].join(' | ') ?? '';
                        return {
                          label: (
                            <Tooltip title={values} placement="top">
                              {values}
                            </Tooltip>
                          ),
                          value: item?._id ?? '',
                        };
                      }) ?? []
                    );
                  }}
                  countSelector={({ companies }) => companies?.count ?? 0}
                  allowSearch
                  placeholder="Gstin, Tradename/legalname"
                  allowClear
                  disabled={!!id}
                  /* any used to onchange value type incompatibility */
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => {
                    const gstNumber = e?.label?.props?.title?.split(' | ')[1];
                    const tradeName = e?.label?.props?.title?.split(' | ')[0];
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.COMPANY_ID,
                      e && (e.value as string),
                      {
                        [COMMON_QUERY_PARAMS.NAME]: gstNumber,
                        [COMMON_QUERY_PARAMS.CLIENT_LABEL]: tradeName,
                      },
                    ),
                      setSelectedRowKeys([]);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'type']}
                label={LABEL_TEXT.TYPE}
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={ADDITIONAL_NOTICE_TYPE}
                  onChange={(e: string) => {
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.NOTICE_TYPE,
                      e ?? null,
                    ),
                      setSelectedRowKeys([]);
                  }}
                  allowClear
                  showSearch
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'status']}
                label={LABEL_TEXT.STATUS}
                className="select"
              >
                <Select
                  placeholder="All"
                  options={ADDITIONAL_NOTICE_STATUS_OPTIONS}
                  allowClear
                  showSearch
                  suffixIcon={<SelectDropdownIcon />}
                  onChange={(e: string) => {
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.STATUS,
                      e ?? null,
                    ),
                      setSelectedRowKeys([]);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'section']}
                label={LABEL_TEXT.SECTION_NO}
                className="select"
              >
                <Select
                  placeholder="All"
                  options={sectionOptions}
                  allowClear
                  suffixIcon={<SelectDropdownIcon />}
                  onChange={(e: string) => {
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.SECTION,
                      e ?? null,
                    ),
                      setSelectedRowKeys([]);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'duration']}
                label={LABEL_TEXT.DURATION}
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={DURATION_TYPE}
                  onChange={(e: string) => {
                    handleDurationNavigation(e), setSelectedRowKeys([]);
                  }}
                  allowClear
                />
              </Form.Item>
              {filters?.duration === DurationType.Custom && (
                <Form.Item
                  name={['filters', 'period']}
                  label={LABEL_TEXT.PERIOD}
                  className="select"
                >
                  <RangePicker
                    allowClear
                    onChange={(date) => {
                      handleDateRangeNavigation(date), setSelectedRowKeys([]);
                    }}
                    format={defaultDateFormat}
                    disabledDate={disabledYear}
                  />
                </Form.Item>
              )}
              <Form.Item className="d-flex align-end align-self-end">
                <Button
                  type="link"
                  onClick={handleClear}
                  disabled={
                    !(
                      (id ? !filters?.companyId : filters?.companyId) ||
                      filters?.status ||
                      filters?.type ||
                      filters?.duration ||
                      filters?.search ||
                      filters?.section
                    )
                  }
                >
                  {LABEL_TEXT.CLEAR_ALL}
                </Button>
              </Form.Item>
            </div>
          </Form>
          <TableComponent
            columns={columns}
            dataSource={additionalNotices?.data as AdditionalNotice[]}
            pagination={false}
            rowSelection={rowSelection}
            rowKey="_id"
            scroll={{ x: 'max-content' }}
            locale={EMPTY_STATE}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
        {additionalNotices?.count ? (
          <CommonPagination
            count={additionalNotices?.count}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        ) : null}
      </Card>
      <CommonModal
        open={isModalOpen}
        title="Update Status"
        footer={false}
        closable={true}
        onCancel={cancelModal}
        maskClosable={false}
      >
        <div className="create-forms-form">
          <Form
            onFinish={updateStateForm}
            form={statusForm}
            layout="vertical"
            preserve={false}
          >
            <Form.Item
              label={LABEL_TEXT.STATUS}
              name={['statusForm', 'status']}
              rules={[
                { ...required, message: MESSAGE?.required, whitespace: true },
              ]}
            >
              <Select
                suffixIcon={<SelectDropdownIcon />}
                options={ADDITIONAL_NOTICE_STATUS_OPTIONS}
                placeholder="Change Status"
              />
            </Form.Item>
            <Form.Item
              label={LABEL_TEXT.REMARK}
              name={['statusForm', 'remarks']}
              rules={[{ whitespace: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={LABEL_TEXT.REMARK}
              name={['statusForm', 'ids']}
              hidden
            >
              <Select suffixIcon={<SelectDropdownIcon />} mode="multiple" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              loading={updateStatusLoading}
              disabled={isEmpty(updateBulkStatus?.status)}
            >
              {LABEL_TEXT.UPDATE}
            </Button>
          </Form>
        </div>
      </CommonModal>
      <AttachmentModal
        open={openModal}
        title="Attachments"
        closable={true}
        onCancel={() => setOpenModal(false)}
        attachments={attachments}
      />
    </div>
  );
};

export default NoticeOrder;
