import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Card, Collapse, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  AdditionalNotice,
  Attachment,
  Reply,
} from '../../../__generated__/graphql';
import { DownArrow, FilePDF, PdfIcon, UpArrow } from '../../../assets/svg';
import {
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  EMPTY_STATE,
} from '../../../common/constants';
import { LABEL_TEXT } from '../../../common/labelTexts';
import TableComponent from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import CommonModal from '../../../components/modals/CommonModal';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import useRouter from '../../../hooks/useRouter';
import { COMPANY } from '../companies/graphql/Queries';
import ProfileDetailCard from './components/ProfileDetailCard';
import { NOTICE_DETAILS } from './graphql/Queries';
import './notice.less';

const { Text } = Typography;

const NoticesOrdersDetail = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get(COMMON_QUERY_PARAMS.NOTICE_ID);
  const { params } = useRouter();
  const { id: companyId } = params;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [attachments, setAttachment] = useState<Attachment[]>([]);
  const isAck = searchParams.get(COMMON_QUERY_PARAMS.IS_ACK);

  const { data: { additionalNotice = {} } = {}, loading } = useQuery(
    NOTICE_DETAILS,
    {
      fetchPolicy: 'network-only',
      onError() {},
      variables: { where: { id: id } },
    },
  );

  const paymentDate =
    additionalNotice?.APPLICATION?.find((item) => item?.paymentDate)
      ?.paymentDate || null;

  const [getCompany, { data: company }] = useLazyQuery(COMPANY, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    if (companyId) {
      getCompany({
        variables: {
          where: { id: companyId },
        },
      });
    }
  }, [companyId]);

  const handleAttachment = (item: AdditionalNotice) => {
    if ((item?.attachments?.length ?? 0) > 0) {
      setOpenModal(true);
      setAttachment(item?.attachments as Attachment[]);
    }
  };

  const intimationsColumns = [
    {
      title: LABEL_TEXT.TYPE,
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => text ?? '-',
    },
    {
      title: LABEL_TEXT.REFERENCE_NO,
      dataIndex: 'refId',
      key: 'refId',
      render: (text: string) => text || '-',
    },
    {
      title: LABEL_TEXT.ISSUE_DATE,
      dataIndex: 'issuanceDate',
      key: 'issuanceDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: LABEL_TEXT.REPLY_DUE_DATE,
      dataIndex: 'replyDueDate',
      key: 'replyDueDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: LABEL_TEXT.SECTION_NO,
      dataIndex: 'sectionNo',
      key: 'sectionNo',
      render: (text: string) => text || '-',
    },
    {
      title: LABEL_TEXT.ATTACHMENTS,
      key: 'sectionNo',
      render: (_: string, item: AdditionalNotice) => {
        return (
          <>
            {(item?.attachments?.length ?? 0) > 0 ? (
              <div className="d-flex flex-horizontal align-center gap-8">
                <div>
                  <PdfIcon />
                </div>
                <div className="file-title attachment-text">
                  {item?.attachments?.[0]?.name}
                </div>
                <div
                  className="file-view-btn attachment-text pointer"
                  onClick={() => handleAttachment(item)}
                >
                  {LABEL_TEXT.VIEW_ALL}
                </div>
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
  ];

  const noticesColumns = [
    {
      title: LABEL_TEXT.TYPE,
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => text ?? '-',
    },
    {
      title: LABEL_TEXT.REFERENCE_NO,
      dataIndex: 'refId',
      key: 'refId',
      render: (text: string) => text || '-',
    },
    {
      title: LABEL_TEXT.ISSUE_DATE,
      dataIndex: 'issuanceDate',
      key: 'issuanceDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    {
      title: LABEL_TEXT.REPLY_DUE_DATE,
      dataIndex: 'replyDueDate',
      key: 'replyDueDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    /* kept for future use */
    /* {
      title: 'Notice Issued by',
      dataIndex: 'replyDueDate',
      key: 'replyDueDate',
      render: (text: string) => '_check_',
    }, */
    {
      title: LABEL_TEXT.PERSONAL_HEARING,
      dataIndex: 'personalHearing',
      key: 'personalHearing',
      render: (text: string) => text ?? '-',
    },
    {
      title: LABEL_TEXT.SECTION_NO,
      dataIndex: 'sectionNo',
      key: 'sectionNo',
      render: (text: string) => text || '-',
    },
    {
      title: LABEL_TEXT.FULL_FINANCIAL_YEAR,
      dataIndex: 'financialYear',
      key: 'financialYear',
      render: (text: string) => text || '-',
    },
    /* kept for future use */
    /*  {
      title: LABEL_TEXT.SUBJECT,
      dataIndex: 'replyDueDate',
      key: 'replyDueDate',
      render: (text: string) => '_check_',
    }, */
    {
      title: LABEL_TEXT.ATTACHMENTS,
      key: 'attachments',
      render: (_: string, item: AdditionalNotice) => {
        return (
          <>
            {(item?.attachments?.length ?? 0) > 0 ? (
              <div className="d-flex flex-horizontal align-center gap-8">
                <div>
                  <PdfIcon />
                </div>
                <div className="file-title attachment-text">
                  {item?.attachments?.[0]?.name}
                </div>
                <div
                  className="file-view-btn attachment-text pointer"
                  onClick={() => handleAttachment(item)}
                >
                  {LABEL_TEXT.VIEW_ALL}
                </div>
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
  ];

  const repliesColumns = [
    {
      title: LABEL_TEXT.TYPE,
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => text ?? '-',
    },
    {
      title: LABEL_TEXT.REPLY_FILED_AGAINST,
      dataIndex: 'repliedAgainstFiled',
      key: 'repliedAgainstFiled',
      render: (text: string) => text ?? '-',
    },
    {
      title: LABEL_TEXT.REPLY_DATE,
      dataIndex: 'replyDate',
      key: 'replyDate',
      render: (date: string) =>
        date ? dayjs(date).format(defaultDateFormat) : '-',
    },
    /* kept for future use */
    /*   {
      title: 'Original Due Date',
      dataIndex: 'pan',
      key: 'pan',
      render: (text: string) => '_check_',
    },
    {
      title: 'Filed Up',
      dataIndex: 'pan',
      key: 'pan',
      render: (text: string) => '_check_',
    }, */
    {
      title: LABEL_TEXT.OPTION_FOR_PERSONAL_HEARING,
      dataIndex: 'personalHearing',
      key: 'personalHearing',
      render: (text: string) => text ?? '-',
    },
    {
      title: LABEL_TEXT.ATTACHMENTS,
      key: 'attachments',
      render: (_: string, item: AdditionalNotice) => {
        return (
          <>
            {(item?.attachments?.length ?? 0) > 0 ? (
              <div className="d-flex flex-horizontal align-center gap-8">
                <div>
                  <PdfIcon />
                </div>
                <div className="file-title attachment-text">
                  {item?.attachments?.[0]?.name}
                </div>
                <div
                  className="file-view-btn attachment-text pointer"
                  onClick={() => handleAttachment(item)}
                >
                  {LABEL_TEXT.VIEW_ALL}
                </div>
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
  ];

  const ordersColumns = [
    {
      title: LABEL_TEXT.TYPE,
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => text ?? '-',
    },
    {
      title: LABEL_TEXT.ORDER_NO,
      key: 'refId',
      render: (_: string, item: AdditionalNotice) =>
        item?.section === 'ORDER' ? item?.refId : '-',
    },
    {
      title: LABEL_TEXT.ORDER_DATE,
      key: 'issuanceDate',
      render: (_: string, item: AdditionalNotice) =>
        item?.section === 'ORDER'
          ? dayjs(item.issuanceDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: LABEL_TEXT.ATTACHMENTS,
      key: 'attachments',
      render: (_: string, item: AdditionalNotice) => {
        return (
          <>
            {(item?.attachments?.length ?? 0) > 0 ? (
              <div className="d-flex flex-horizontal align-center gap-8">
                <div>
                  <PdfIcon />
                </div>
                <div className="file-title attachment-text">
                  {item?.attachments?.[0]?.name}
                </div>
                <div
                  className="file-view-btn attachment-text pointer"
                  onClick={() => handleAttachment(item)}
                >
                  {LABEL_TEXT.VIEW_ALL}
                </div>
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
  ];
  const applicationsColumns = [
    {
      title: LABEL_TEXT.TYPE_OF_DOCUMENTS,
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => text ?? '-',
    },
    {
      title: LABEL_TEXT.VIEW_DOCUMENTS,
      key: 'attachments',
      render: (_: string, item: AdditionalNotice) => {
        return (
          <>
            {(item?.attachments?.length ?? 0) > 0 ? (
              <div className="d-flex flex-horizontal align-center gap-8">
                <div>
                  <PdfIcon />
                </div>
                <div className="file-title attachment-text">
                  {item?.attachments?.[0]?.name}
                </div>
                <div
                  className="file-view-btn attachment-text pointer"
                  onClick={() => handleAttachment(item)}
                >
                  {LABEL_TEXT.VIEW_ALL}
                </div>
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
  ];

  const ackIntimationColumns = [
    {
      title: LABEL_TEXT.TYPE,
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => text ?? '-',
    },
    {
      title: LABEL_TEXT.REFERENCE_NO,
      key: 'refId',
      render: (_: string, item: AdditionalNotice) => item?.refId ?? '-',
    },
    {
      title: LABEL_TEXT.DATE,
      key: 'issuanceDate',
      render: (_: string, item: AdditionalNotice) =>
        item.issuanceDate
          ? dayjs(item.issuanceDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: LABEL_TEXT.ACK_INTIMATION_AND_ATTACHMENTS,
      key: 'attachments',
      render: (_: string, item: AdditionalNotice) => {
        return (
          <>
            {(item?.attachments?.length ?? 0) > 0 ? (
              <div className="d-flex flex-horizontal align-center gap-8">
                <div>
                  <PdfIcon />
                </div>
                <div className="file-title attachment-text">
                  {item?.attachments?.[0]?.name}
                </div>
                <div
                  className="file-view-btn attachment-text pointer"
                  onClick={() => handleAttachment(item)}
                >
                  {LABEL_TEXT.VIEW_ALL}
                </div>
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
  ];

  const getFormattedDate = (date?: string) =>
    date ? dayjs(date).format(defaultDateFormat) : '-';

  return (
    <div className="container">
      <LoaderComponent spinning={loading}>
        <div className="mt-16 mb-16 d-flex justify-between">
          <MyBreadcrumb
            username={company?.company?.gstNumber ?? ''}
            id={(companyId || company?.company?._id) ?? ''}
          />
        </div>
        <Text className="heading">{LABEL_TEXT.ADDITIONAL_NOTICE_ORDERS}</Text>
        <ProfileDetailCard
          caseId={additionalNotice?.arn ?? ''}
          gstInNumber={additionalNotice?.gstIn ?? ''}
          applicationDate={
            isAck === 'true'
              ? getFormattedDate(paymentDate)
              : getFormattedDate(additionalNotice?.arnDate)
          }
          status={additionalNotice?.status ?? ''}
        />
        {isAck === 'true' ? (
          <>
            <Card className="mb-24">
              <Collapse
                className="table-collapse"
                defaultActiveKey={['applications']}
                items={[
                  {
                    key: 'applications',
                    label: (
                      <Text className="title">{LABEL_TEXT.APPLICATIONS}</Text>
                    ),
                    children: (
                      <TableComponent
                        rowKey=""
                        columns={applicationsColumns as []}
                        dataSource={
                          additionalNotice?.APPLICATION as AdditionalNotice[]
                        }
                        locale={EMPTY_STATE}
                        scroll={{ x: 'max-content' }}
                      />
                    ),
                  },
                ]}
                expandIcon={({ isActive }) => (
                  <Button
                    icon={!isActive ? <DownArrow /> : <UpArrow />}
                    className="justify-center"
                  />
                )}
                expandIconPosition="end"
                accordion={false}
              />
            </Card>
            <Card className="mb-24">
              <Collapse
                className="table-collapse"
                defaultActiveKey={['aCKIntimations']}
                items={[
                  {
                    key: 'aCKIntimations',
                    label: (
                      <Text className="title">
                        {LABEL_TEXT.ACK_INTIMATIONS}
                      </Text>
                    ),
                    children: (
                      <TableComponent
                        rowKey=""
                        columns={ackIntimationColumns as []}
                        dataSource={
                          additionalNotice?.ORDER as AdditionalNotice[]
                        }
                        locale={EMPTY_STATE}
                        scroll={{ x: 'max-content' }}
                      />
                    ),
                  },
                ]}
                expandIcon={({ isActive }) => (
                  <Button
                    icon={!isActive ? <DownArrow /> : <UpArrow />}
                    className="justify-center"
                  />
                )}
                expandIconPosition="end"
                accordion={false}
              />
            </Card>
          </>
        ) : (
          <>
            <Card className="mb-24">
              <Collapse
                className="table-collapse"
                defaultActiveKey={['intimations']}
                items={[
                  {
                    key: 'intimations',
                    label: (
                      <Text className="title">{LABEL_TEXT.INTIMATIONS}</Text>
                    ),
                    children: (
                      <TableComponent
                        rowKey=""
                        columns={intimationsColumns as []}
                        dataSource={
                          additionalNotice?.INTIMATION as AdditionalNotice[]
                        }
                        locale={EMPTY_STATE}
                        scroll={{ x: 'max-content' }}
                      />
                    ),
                  },
                ]}
                expandIcon={({ isActive }) => (
                  <Button
                    icon={!isActive ? <DownArrow /> : <UpArrow />}
                    className="justify-center"
                  />
                )}
                expandIconPosition="end"
                accordion={false}
              />
            </Card>
            <Card className="mb-24">
              <Collapse
                defaultActiveKey={['notices']}
                className="table-collapse"
                items={[
                  {
                    key: 'notices',
                    label: <Text className="title">{LABEL_TEXT.NOTICES}</Text>,
                    children: (
                      <TableComponent
                        rowKey=""
                        columns={noticesColumns as []}
                        dataSource={
                          additionalNotice?.NOTICE as AdditionalNotice[]
                        }
                        locale={EMPTY_STATE}
                        scroll={{ x: 'max-content' }}
                      />
                    ),
                  },
                ]}
                expandIcon={({ isActive }) => (
                  <Button
                    icon={!isActive ? <DownArrow /> : <UpArrow />}
                    className="justify-center"
                  />
                )}
                expandIconPosition="end"
                accordion={false}
              />
            </Card>
            <Card className="mb-24">
              <Collapse
                defaultActiveKey={['replies']}
                className="table-collapse"
                items={[
                  {
                    key: 'replies',
                    label: <Text className="title">{LABEL_TEXT.REPLIES}</Text>,
                    children: (
                      <TableComponent
                        rowKey=""
                        columns={repliesColumns as []}
                        dataSource={additionalNotice?.REPLY as Reply[]}
                        locale={EMPTY_STATE}
                        scroll={{ x: 'max-content' }}
                      />
                    ),
                  },
                ]}
                expandIcon={({ isActive }) => (
                  <Button
                    icon={!isActive ? <DownArrow /> : <UpArrow />}
                    className="justify-center"
                  />
                )}
                expandIconPosition="end"
                accordion={false}
              />
            </Card>
            <Card className="mb-24">
              <Collapse
                defaultActiveKey={['orders']}
                className="table-collapse"
                items={[
                  {
                    key: 'orders',
                    label: <Text className="title">{LABEL_TEXT.ORDERS}</Text>,
                    children: (
                      <TableComponent
                        rowKey=""
                        columns={ordersColumns as []}
                        dataSource={
                          additionalNotice?.ORDER as AdditionalNotice[]
                        }
                        locale={EMPTY_STATE}
                        scroll={{ x: 'max-content' }}
                      />
                    ),
                  },
                ]}
                expandIcon={({ isActive }) => (
                  <Button
                    icon={!isActive ? <DownArrow /> : <UpArrow />}
                    className="justify-center"
                  />
                )}
                expandIconPosition="end"
                accordion={false}
              />
            </Card>
          </>
        )}
      </LoaderComponent>
      <CommonModal
        className="h-512"
        title="Attachments"
        open={openModal}
        footer={false}
        closable={true}
        onCancel={() => {
          setOpenModal(false);
          setAttachment([]);
        }}
        wrapClassName="attachment-modal"
      >
        <div className="d-flex gap-16 flex-vertical">
          {attachments?.map((file: Attachment, index: number) => (
            <div className="d-flex align-center" key={index}>
              <span className="mr-8 d-flex">
                <FilePDF />
              </span>
              <Link
                className="color-blue max-width-430"
                to={file?.url || ''}
                download
                target="_blank"
              >
                {file?.name}
              </Link>
            </div>
          ))}
        </div>
      </CommonModal>
    </div>
  );
};

export default NoticesOrdersDetail;
