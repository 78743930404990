import { Button, ConfigProvider } from 'antd';
import { ROUTES } from '../../../common/constants';
import { CommonButtonProps } from '../../../types/common.type';

const CommonButton = ({ text }: CommonButtonProps) => {
  return (
    <div className="btn-wrapper">
      <ConfigProvider
        theme={{
          components: {
            Button: {
              borderRadius: 8,
              colorPrimary: 'var(--system-Links)',
              colorPrimaryHover: 'var(--border-calendar)',
              colorPrimaryActive: 'var(--system-Links)',
              colorLink: 'var(--system-Links)',
              colorLinkHover: 'var(--border-calendar)',
              colorLinkActive: 'var(--system-Links)',
              defaultColor: 'var(--system-Links)',
              defaultBorderColor: 'var(--system-Links)',
              primaryShadow: 'unset',
              colorTextDisabled: '#F4F4F4',
              colorBgContainerDisabled: 'var(--system-Links)',
              colorText: 'var(--primary)',
              borderColorDisabled: 'rgb(202,201,201)',
              controlHeight: 40,
              fontSize: 16,
            },
          },
        }}
      >
        <Button
          type="primary"
          onClick={() =>
            (window.location.href = `${process.env.REACT_APP_REDIRECT_PROFILE_URL}${ROUTES?.SIGNUP}/?redirect=${window?.location?.href}`)
          }
        >
          {text}
        </Button>
      </ConfigProvider>
    </div>
  );
};

export default CommonButton;
